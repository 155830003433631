import { EnumToArray } from '@/utils/common';

export enum NOTIFICATION_TABS {
  TAB_ALL = 'all',
  TAB_UNREAD = 'unread',
}

export const NotificationTabsList = EnumToArray(NOTIFICATION_TABS);

export enum NOTIFICATIONS_SOCKET_EVENTS {
  NEW_NOTIFICATION = 'newNotification',
}

export enum NOTIFICATIONS_ACTION {
  STATUS_UPDATE = 'status_update',
}
