interface ControlState {
  isFocused: boolean;
}

export interface CustomStyles {
  control: (
    provided: React.CSSProperties,
    state: ControlState,
  ) => React.CSSProperties;
  menu: (provided: React.CSSProperties) => React.CSSProperties;
  option: (
    provided: React.CSSProperties,
    state: ControlState,
  ) => React.CSSProperties;
  indicatorsContainer: () => React.CSSProperties;
  placeholder: (provided: React.CSSProperties) => React.CSSProperties;
}

export const customStyles: CustomStyles = {
  control: (provided) => ({
    ...provided,
    '[type="text"]': {
      fontFamily: 'Helvetica, sans-serif !important',
      fontSize: 13,
      fontWeight: 900,
    },
    'display': 'flex',
    'minHeight': '48px',
    'maxHeight': '48px',
    'maxWidth': '100%',
    'padding': '10px',
    'overflowX': 'scroll',
    'flexWrap': 'nowrap',
    'outline': 'none',
    'borderRadius': '6px',
    'borderColor': '#E4E6E9',
    'boxShadow': undefined,
    '&:hover': {
      borderColor: '#E4E6E9',
      overflow: 'hidden',
    },
    'cursor': 'pointer',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: '#fff',
    zIndex: 9999,
    borderRadius: '12px',
    padding: '5px 0',
  }),
  option: (provided) => ({
    ...provided,
    'fontSize': '13px',
    '&:hover': {
      backgroundColor: '#EAF3F8',
    },
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    flexDirection: 'row',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'gray',
    fontSize: '15px',
  }),
};
