import React from 'react';

interface IRatingLeave extends React.SVGProps<SVGSVGElement> {
  isFill: boolean;
}

const RatingLeave: React.FC<IRatingLeave> = ({ isFill, ...props }) => (
  <svg
    width='37'
    height='35'
    viewBox='0 0 37 35'
    {...props}
    fill={isFill ? '#ffde21' : 'transparent'}
  >
    <path
      d='M18.0916 1L23.3729 11.6994L35.1833 13.4256L26.6374 21.7492L28.6543 33.5083L18.0916 27.9535L7.529 33.5083L9.54581 21.7492L1 13.4256L12.8103 11.6994L18.0916 1Z'
      stroke={isFill ? '#ffde21' : '#525252'}
      strokeOpacity='0.84'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default RatingLeave;
