import * as React from 'react';
import { SVGProps } from 'react';
const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={19}
    viewBox='0 0 23 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M15 18.5744C14.9141 20.735 13.1136 22.5696 10.7016 22.5106C10.1404 22.4968 9.44682 22.3012 8.05964 21.9099C4.72122 20.9683 1.82314 19.3857 1.12781 15.8404C1 15.1887 1 14.4554 1 12.9888L1 11.0352C1 9.56851 1 8.83518 1.12781 8.1835C1.82314 4.63823 4.72122 3.05565 8.05964 2.11399C9.44683 1.72272 10.1404 1.52708 10.7016 1.51335C13.1136 1.45434 14.9141 3.28888 15 5.44947'
      stroke={props.color || '#FF4646'}
      strokeWidth={2}
      strokeLinecap='round'
    />
    <path
      d='M22.0013 12.0119H9.16797M22.0013 12.0119C22.0013 11.1949 19.6746 9.66867 19.0846 9.09521M22.0013 12.0119C22.0013 12.8288 19.6746 14.3551 19.0846 14.9285'
      stroke={props.color || '#FF4646'}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default LogoutIcon;
