import { useEffect, useState } from 'react';
import {
  Controller,
  RegisterOptions,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { ISetNewPasswordFields, ISignUpFields } from '@/@types/auth';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { INPUT_ERRORS } from '@/constants';
import { GoogleVerificationURL } from '@/constants/urls';
import { useGenericMutation } from '@/hooks/useMutationData';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { signup } from '@/services/auth';
import { EmailRegex, validateNewPasswordFields } from '@/utils/common';

import GoogleIcon from '../../../assets/svgs/google.svg';
import AuthHeadingsAndDesc from '../components/HeadingAndDesc';
import LineForOrOtion from '../components/LineForOrOtion';

const SignUp = () => {
  const staticText = strings.signUp;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const initialValues = {
    firstName: '',
    lasttName: '',
    email: '',
    password: '',
    reEnterPassword: '',
  };

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<ISignUpFields>({
    mode: 'all',
    defaultValues: initialValues,
  });

  const signupMutation = useGenericMutation<ISignUpFields, string | boolean>(
    signup,
    {
      onSuccess: (response) => {
        if (typeof response === 'string') {
          toast.success(response, { duration: 5000 });

          navigate(ROUTES.VERIFY_EMAIL, {
            state: { verificationEmail: getValues('email') },
          });
        }
      },
    },
  );

  const handleSignUp: SubmitHandler<ISignUpFields> = (formData) => {
    signupMutation.mutate(formData);
  };

  const { newPasswordRules, confirmNewPasswordRules } =
    validateNewPasswordFields(watch('password'));

  const handleGoogleSignUp = () => {
    setLoading(true);
    window.location.href = GoogleVerificationURL;
  };

  useEffect(() => {
    if (watch('password') && watch('reEnterPassword')) {
      trigger('reEnterPassword');
    }
  }, [watch('password'), trigger]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => setLoading(false), 5000);
    }
  }, [loading]);

  return (
    <>
      <AuthHeadingsAndDesc title={staticText.title} desc={staticText.desc} />
      <div className='space-y-4'>
        <Controller
          control={control}
          name='firstName'
          rules={{
            required: INPUT_ERRORS.FIRST_NAME.required,
          }}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.firstName}
              placeholder={staticText.firstNamePlaceholder}
              name={name}
              value={value}
              isRequired
              onChange={onChange}
              errors={errors}
            />
          )}
        />
        <Controller
          control={control}
          name='lastName'
          rules={{
            required: INPUT_ERRORS.LAST_NAME.required,
          }}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.lastName}
              placeholder={staticText.lastNamePlaceholder}
              name={name}
              value={value}
              isRequired
              onChange={onChange}
              errors={errors}
            />
          )}
        />
        <Controller
          control={control}
          name='email'
          rules={{
            required: INPUT_ERRORS.EMAIL.required,
            pattern: {
              value: EmailRegex,
              message: INPUT_ERRORS.EMAIL.pattern,
            },
          }}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.email}
              placeholder={staticText.emailPlaceholder}
              name={name}
              value={value}
              isRequired
              onChange={onChange}
              errors={errors}
            />
          )}
        />
        <Controller
          control={control}
          name='password'
          rules={
            newPasswordRules as RegisterOptions<
              ISetNewPasswordFields,
              'password'
            >
          }
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.password}
              placeholder={staticText.passwodPlaceholder}
              name={name}
              value={value}
              isRequired
              onChange={onChange}
              type='password'
              errors={errors}
            />
          )}
        />
        <Controller
          control={control}
          name='reEnterPassword'
          rules={
            confirmNewPasswordRules as RegisterOptions<
              ISetNewPasswordFields,
              'reEnterPassword'
            >
          }
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.reEnterPassword}
              placeholder={staticText.reEnterPasswordPlaceholder}
              name={name}
              value={value}
              type='password'
              isRequired
              onChange={onChange}
              errors={errors}
              hideInfoIcon
            />
          )}
        />
        <Button
          className='mt-6 text-base'
          onClick={handleSubmit(handleSignUp)}
          loading={signupMutation.isPending}
        >
          {staticText.btnText}
        </Button>
        <LineForOrOtion />
        <Button
          variant='ghost'
          className='bg-white font-bold'
          onClick={handleGoogleSignUp}
          loading={loading}
        >
          {strings.continueWithGoogle}
          <img src={GoogleIcon} alt='google' className='ml-3' />
        </Button>
        <div className='w-full flex justify-center'>
          <Typography>
            {staticText.alreadyHaveAnAccount}
            &nbsp;
            <a className='cursor-pointer text-primary' href={ROUTES.LOGIN}>
              {staticText.signIn}
            </a>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default SignUp;
