/* eslint-disable max-lines */
import React from 'react';

import { IUser } from '@/@types/auth';
import { ICandidate } from '@/@types/candidate';
// import { Separator } from '@/components/separator';
import ProfileBadge from '@/components/ProfileBadge';
import { Skeleton } from '@/components/ui/skeleton';
import { USER_TYPE } from '@/constants';
import { cn } from '@/lib/utils';
import { useChatContext } from '@/provider/ChatProvider';
// import { useDrawerContext } from '@/provider/DrawerProvider';
import { GetFormattedName } from '@/utils/common';

// import TicketTooltip from './TicketTooltip';

const ChatHeader = () => {
  // const { isSmallScreen } = useDrawerContext();
  const { activeChat } = useChatContext();

  // const { ticket, user } = activeChat || {};
  const { user } = activeChat || {};
  const { userDetails, jobPosition, department, experienceYears } =
    (user as ICandidate) || {};
  const recipient = (userDetails || user) as IUser;
  const isRecipientCandidate = recipient?.profileType === USER_TYPE.CANDIDATE;

  const Content = () => {
    if (!activeChat)
      return (
        <div className='flex items-center space-x-4 w-full'>
          <Skeleton className='size-11 rounded-full' />
          <div className='space-y-2 w-full'>
            <Skeleton className='h-4 w-1/6' />
            <Skeleton className='h-4 w-2/6' />
          </div>
        </div>
      );
    return (
      <div className='flex flex-col md:flex-row gap-4 w-full items-start md:items-center'>
        <ProfileBadge
          name={GetFormattedName(recipient)}
          profilePicture={recipient?.avatar as string}
          avatarClassName='size-11'
          attribute={
            isRecipientCandidate
              ? [
                  jobPosition,
                  department,
                  experienceYears ? `${experienceYears} Years` : '',
                ]
                  .filter(Boolean)
                  .join(' | ')
              : recipient.email
          }
          nameClassName='text-base font-bold'
          attributeClassName={cn('text-greyishBlack font-semibold', {
            lowercase: !isRecipientCandidate,
          })}
        />
        {/* {ticket && !isSmallScreen ? (
          <>
            <Separator
              orientation='vertical'
              className='md:block hidden h-12 '
            />
            <TicketTooltip />
          </>
        ) : null} */}
      </div>
    );
  };

  return <div className='flex items-center gap-4 w-full'>{Content()}</div>;
};

export default ChatHeader;
