import React from 'react';

import { IFilterOptions } from '@/@types/dashboard';
import Tags from '@/components/Tags';
import { Typography } from '@/components/Typography';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';

interface AppliedFilterSectionProps {
  handleRemoveTag?: (tag: keyof IFilterOptions) => void;
  filters?: IFilterOptions | IFilterOptions;
  className?: string;
}

const AppliedFilterSection: React.FC<AppliedFilterSectionProps> = ({
  handleRemoveTag,
  filters,
  className,
}) => {
  const staticText = strings.JobsSection;

  // Transform filters into the format expected by the component
  const filterTags = Object.entries(filters || {}).filter(([, value]) => value);

  return (
    <div className={cn('flex flex-col lg:flex-row w-full gap-1', className)}>
      <Typography className='md:text-[15px] font-bold whitespace-nowrap mr-1'>
        {staticText.appliedFilter}:
      </Typography>
      {filterTags.length > 0 && (
        <div className='flex gap-2 flex-wrap w-full'>
          {filterTags.map(([key, value], index) => {
            return (
              <Tags
                key={`key-${index}`}
                text={typeof value === 'object' ? value?.label : String(value)}
                tagHeading={key
                  .replace(/filter/i, '')
                  .replace(/assignment/i, '')
                  .replace(/([A-Z])/g, ' $1')
                  .trim()}
                variant='blue'
                handleClose={() =>
                  handleRemoveTag?.(key as keyof IFilterOptions)
                }
                containerClassName='py-1 !text-xs h-auto w-fit text-sm'
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AppliedFilterSection;
