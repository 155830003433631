import React, { FC } from 'react';

import RatingLeave from '@/components/RatingStar';

interface Props {
  length: number;
  rating: number;
  handleRating: React.Dispatch<React.SetStateAction<number>>;
}

const createBooleanArray = (num: number, filledCount: number) =>
  Array(num)
    .fill(false)
    .map((_, i) => i < filledCount);

const Rating: FC<Props> = ({ length, rating, handleRating }) => {
  const resultArray = createBooleanArray(length, rating);

  const handleRatings = (indexVal: number) => {
    const newFilledStars = indexVal + 1;
    handleRating(newFilledStars);
  };

  return (
    <div className='flex gap-4 items-center justify-center my-4'>
      {resultArray.map((isFilled, index) => (
        <button
          key={index}
          onClick={() => handleRatings(index)}
          className='p-0 bg-transparent border-none cursor-pointer'
        >
          <RatingLeave isFill={isFilled} />
        </button>
      ))}
    </div>
  );
};

export default Rating;
