import React from 'react';
import toast from 'react-hot-toast';
import { LuCopy } from 'react-icons/lu';

import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/seperator';
import { Textarea } from '@/components/ui/textarea';
import { strings } from '@/locales';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const ReferFriendModal: React.FC<Props> = ({ open, handleClose }) => {
  const link = location.href;
  const staticText = strings.referFriendModal;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    toast.success(staticText.copied);
  };

  return (
    <Modal
      open={open}
      handleCloseModal={handleClose}
      title={staticText.referFriend}
      contentClassName='max-w-[85%] md:max-w-[600px]'
    >
      <div className='space-y-3 sm:space-y-6'>
        <Typography className='font-extrabold'>
          {staticText.sharedLink}
        </Typography>
        <div className='rounded-md border border-slate-200 flex gap-3 px-2 py-1 items-center'>
          <Textarea
            value={link}
            readOnly
            className='w-full resize-none min-h-6 sm:h-6 border-none px-0 py-0 mb-0'
          />
          <Separator orientation='vertical' className='h-6' />
          <Button
            variant='link'
            onClick={handleCopyLink}
            size='sm'
            className='text-black w-auto p-0 md:p-0 h-auto'
          >
            <LuCopy size={20} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReferFriendModal;
