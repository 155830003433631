export enum MESSAGE_TYPE {
  SYSTEM_GENERATED = 'systemGenerated',
  USER_GENERATED = 'userGenerated',
}

export enum CHAT_SOCKET_EVENTS {
  SEND_MESSAGE = 'sendMessage',
  RECIEVE_MESSAGE = 'receiveMessage',
  JOIN_CONVERSATION = 'joinConversation',
  LEAVE_CONVERSATION = 'leaveConversation',
  MESSAGE_NOTIFICATION = 'messageNotification',
}

export const urlRegex = /(https?:\/\/[^\s]+)/g;
