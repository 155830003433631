import React from 'react';

import { Typography } from '@/components/Typography';
import { strings } from '@/locales';

import AuthImage from '../../../assets/images/authImage.webp';
import Logo from '../../../assets/images/logo.svg';

interface IAuthWrapper {
  children: React.ReactNode;
}

const AuthWrapper: React.FC<IAuthWrapper> = ({ children }) => {
  const staticText = strings.authWrapperText;
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 md:h-screen'>
      <div className='bg-gradient-bg md:flex flex-col justify-between  overflow-hidden hidden'>
        <div className='px-10 md:w-3/4 h-full flex items-center py-16'>
          <Typography variant='heading' className='md:text-4xl text-white'>
            {staticText.title}
          </Typography>
        </div>
        {<img src={AuthImage} className='w-[90%]' />}
      </div>
      <div className='w-11/12 lg:w-3/5 mx-auto pt-12 flex flex-col justify-between pb-6'>
        <div className='my-auto'>
          <img src={Logo} alt='Logo' className='mb-5' />
          {children}
        </div>
        <Typography className='text-center w-full pt-6'>
          © {new Date().getFullYear()} {staticText.footerText}
        </Typography>
      </div>
    </div>
  );
};

export default AuthWrapper;
