import React from 'react';

import BackHeader from '@/components/BackHeader';
import { strings } from '@/locales';
import { useChatContext } from '@/provider/ChatProvider';

import ChatArea from './ChatArea';
import ChatSidebar from './ChatSidebar';
import StartChattingAnimationView from './StartChattingAnimationView';

const ChatScreen = () => {
  const { activeChat, setActiveChat } = useChatContext();

  const staticText = strings.chatScreen;
  const isSmallScreen = window.innerWidth < 1024;
  const showChatArea = activeChat;

  const handleGoBack = () => {
    setActiveChat(null);
  };

  return (
    <div className=' h-[calc(100vh-120px)] flex gap-3'>
      {!isSmallScreen ? (
        <>
          <ChatSidebar />
          {showChatArea ? <ChatArea /> : <StartChattingAnimationView />}
        </>
      ) : (
        <>
          {showChatArea ? (
            <div className='flex flex-col gap-2 w-full'>
              <BackHeader
                title={staticText.chat}
                handleBackBtn={handleGoBack}
              />
              <ChatArea />
            </div>
          ) : (
            <ChatSidebar />
          )}
        </>
      )}
    </div>
  );
};

export default ChatScreen;
