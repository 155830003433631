export const REST_SUB_URL = {
  SIGNUP: '/auth/signup',
  VERIFY_EMAIL: '/auth/verify-email',
  RESEND_VERIFICATION_EMAIL: '/auth/resend-email',
  GOOGLE_INITIATE_VERIFICATION: '/auth/google/initiate',
  GOOGLE_VERIFY: '/auth/google/verify',
  LOGIN: '/auth/candidate-login',
  FORGOT_PASSWORD: '/auth/forgot-password',
  VERIFY_RESET_PASSWORD: '/auth/verify-reset-password',
  RESET_PASSWORD: '/auth/reset-password',
  PROFILE: '/profile',
  GET_ALL_NOTIFICATIONS: '/notifications',
  MARK_NOTIFICATION_READ: '/notifications/mark-as-read',
  MARK_ALL_NOTIFICATIONS_READ: '/notifications/mark-as-all-read',
  GET_UN_READ_NOTIFICATIONS_COUNT: '/notifications/un-read-count',
  CLEAR_UN_READ_NOTIFICATIONS_COUNT: '/notifications/clear-read-count',
  POST_COMPANY_REVIEW: '/reviews',
  GET_ALL_JOBS: '/jobs',
  JOB_BACKOUT: '/jobs/backed-out',
  JOB_APPLY: '/jobs/apply',
  GET_STATISTICS: '/jobs/applied-job/statistics',
  GET_RESPONSE_PERCENTAGE: '/jobs/applied-job/response',
  GET_DEPARTMENTS: '/department',
  GET_COMPANIES: '/department/companies',
  DOCUMENTS: '/documents',
  DOCUMENTS_MULTIPLE: '/documents/multiple',
  GET_CHAT_LIST: '/chat/conversations',
  GET_CHAT: '/chat/conversation',
  READ_MESSAGES: '/chat/read-messages',
  PROFILE_COMPLETE_PERCENTAGE: '/candidate-profile/complete-profile-percentage',
  PROFILE_PERSONAL_INFO_STEP: '/candidate-profile/personal-info',
  PROFILE_ACEDEMIC_HISTORY_STEP: '/candidate-profile/academic-history',
  PROFILE_TRAINING_SEMINARS_STEP: '/candidate-profile/training-seminars',
  PROFILE_WORK_EXPERIENCE_STEP: '/candidate-profile/work-experience',
  PROFILE_SPECIAL_SKILLS_STEP: '/candidate-profile/create-special-skills',
  PROFILE_OTHERS_INFO_STEP: '/candidate-profile/others',
  CANDIDATE_PROFILE: '/candidate-profile',
  RELIGIONS: '/religion',
};

export const GoogleVerificationURL = `${process.env.REACT_APP_BASE_URL}${REST_SUB_URL.GOOGLE_INITIATE_VERIFICATION}`;
