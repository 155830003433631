import toast from 'react-hot-toast';

import { IPaginationResponse } from '@/@types';
import { ICompany, IDepartment, IReligion } from '@/@types/metas';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';

export const fetchDepartments = async (
  page: number,
  departmentSearch?: string,
): Promise<IPaginationResponse<IDepartment> | boolean> => {
  const response = await API.Get<IPaginationResponse<IDepartment>>(
    `${REST_SUB_URL.GET_DEPARTMENTS}?page=${page}&search=${departmentSearch}`,
  );
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const fetchCompanies = async (
  page: number,
  companySearch?: string,
): Promise<IPaginationResponse<ICompany> | boolean> => {
  const response = await API.Get<IPaginationResponse<ICompany>>(
    `${REST_SUB_URL.GET_COMPANIES}?page=${page}&search=${companySearch}`,
  );
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};
export const fetchReligeon = async (): Promise<IReligion[] | null> => {
  const response = await API.Get<IReligion[]>(REST_SUB_URL.RELIGIONS);
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return null;
};
