import React from 'react';

import TooltipComponent from '@/components/Tooltip';
import { Separator } from '@/components/ui/seperator';
import { STEPPER_STEPS } from '@/constants/profile';
import { strings } from '@/locales';

interface ProgressBarProps {
  progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  const staticText = strings.profileStatusSection;
  const segments = STEPPER_STEPS.length;
  const filledSegments = (progress / 100) * segments;

  return (
    <div className='flex items-center justify-center mt-2 md:ml-4'>
      <span className='text-white text-sm mr-2'>{staticText.zeroPercent}</span>
      <TooltipComponent
        trigger={
          <div className='flex space-x-1'>
            {Array.from({ length: segments }, (_, index) => {
              const fillPercentage = Math.max(
                0,
                Math.min(100, (filledSegments - index) * 100),
              );
              return (
                <div key={index} className='h-4 relative cursor-pointer'>
                  <Separator
                    className='mt-1 w-6 sm:w-9 h-2 rounded-sm bg-white'
                    orientation='horizontal'
                  />
                  <Separator
                    className='absolute top-1 h-2 rounded-sm bg-primaryGreen'
                    orientation='horizontal'
                    style={{ width: `${fillPercentage}%` }}
                  />
                </div>
              );
            })}
          </div>
        }
        text={`${Math.round(progress)}% ${staticText.complete}`}
        align='center'
        directionalArrow
      />
      <span className='text-white text-sm ml-2'>
        {staticText.hundreadPercent}
      </span>
    </div>
  );
};

export default ProgressBar;
