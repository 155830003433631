import { io, Socket } from 'socket.io-client';

export let socket: Socket;
const RETRY_INTERVAL = 10000;
export let timeout: NodeJS.Timeout;
const SOCKET_URL = process.env.REACT_APP_WS_URL;

export const initializeSocket = (userId: string) => {
  if (userId) {
    socket = io(SOCKET_URL, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: RETRY_INTERVAL,
      query: {
        userId,
      },
    });
  }
};

export const retryConnectOnFailure = () => {
  timeout = setTimeout(() => {
    if (!socket.connected) {
      socket.connect();
      retryConnectOnFailure();
    }
  }, RETRY_INTERVAL);
};

export const onConnect = () => {
  console.log('Socket connected');
  clearTimeout(timeout);
};

export const onDisconnect = () => {
  console.log('Socket disconnected');
  retryConnectOnFailure();
};
