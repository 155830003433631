import React from 'react';

import { cn } from '@/lib/utils';
import { strings } from '@/locales';

import { Button } from 'src/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog';

export interface ModalProps {
  open: boolean;
  handleCloseModal: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  children?: JSX.Element;
  buttonDisabled?: boolean;
  titleClassName?: string;
  primaryBtnLabel?: string;
  primaryBtnAction?: () => void;
  secondaryBtnLabel?: string;
  secondaryBtnAction?: () => void;
  contentClassName?: string;
  secondaryBtnDisabled?: boolean;
  isLoading?: boolean;
}
const Modal: React.FC<ModalProps> = ({
  open,
  handleCloseModal,
  title,
  children,
  buttonDisabled,
  titleClassName,
  primaryBtnLabel,
  secondaryBtnLabel,
  primaryBtnAction,
  secondaryBtnAction,
  contentClassName,
  secondaryBtnDisabled,
  isLoading,
}) => {
  const handleSuccessButton = () => {
    if (primaryBtnAction) {
      primaryBtnAction();
    } else {
      handleCloseModal(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleCloseModal}>
      <DialogContent
        className={cn('focus:outline-none', contentClassName)}
        aria-describedby={undefined}
      >
        <DialogHeader className='mb-4'>
          <DialogTitle
            className={cn(
              'text-[22px] font-extrabold',
              { hidden: !title },
              titleClassName,
            )}
          >
            {title || strings.common.modal}
          </DialogTitle>
        </DialogHeader>
        {children ? children : null}
        {primaryBtnLabel ? (
          <DialogFooter className='sm:justify-center'>
            <div className='flex gap-3 items-center'>
              {secondaryBtnAction ? (
                <Button
                  variant='outline'
                  className=' md:p-3 lg:p-[22px] disabled:opacity-70 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-transparent'
                  onClick={secondaryBtnAction}
                  disabled={secondaryBtnDisabled}
                >
                  {secondaryBtnLabel
                    ? secondaryBtnLabel
                    : strings.common.cancel}
                </Button>
              ) : (
                <DialogClose asChild>
                  <Button
                    variant='outline'
                    disabled={isLoading}
                    className='md:p-3 lg:p-5 focus-visible:ring-transparent sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
                  >
                    {strings.common.cancel}
                  </Button>
                </DialogClose>
              )}
              <Button
                loading={isLoading}
                onClick={handleSuccessButton}
                className={cn(
                  'flex md:p-3 lg:p-[22px] sm:w-44 font-medium disabled:opacity-70 sm:text-base md:w-32',
                  {
                    'bg-tomatoRed':
                      primaryBtnLabel === strings.common.delete ||
                      primaryBtnLabel === strings.common.reject,
                  },
                )}
                disabled={buttonDisabled || isLoading}
              >
                {primaryBtnLabel}
              </Button>
            </div>
          </DialogFooter>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
