import toast from 'react-hot-toast';
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';

import { IUser } from '@/@types/auth';
import {
  IAcademicHistory,
  IFinalStepPayload,
  IPersonalInfo,
  ISpecialSkills,
  ITrainingSeminar,
  IWorkExperiences,
} from '@/@types/profile';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';

export const getProfileCompletePercentage = async (): Promise<
  number | boolean
> => {
  const response = await API.Get<number>(
    `${REST_SUB_URL.PROFILE_COMPLETE_PERCENTAGE}`,
  );

  if (response.status && response?.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const personalInfoStep = async (
  payload: IPersonalInfo & {
    setAvatarProgress?: (progress: number) => void;
    setResumeProgress?: (progress: number) => void;
  },
): Promise<IPersonalInfo | boolean> => {
  const formData = new FormData();
  const { setAvatarProgress, setResumeProgress, ...rest } = payload;

  Object.entries(rest).forEach(([key, value]) => {
    if (value instanceof FileList) {
      Array.from(value).forEach((file) => formData.append(key, file));
    } else {
      formData.append(key, value as string | Blob);
    }
  });

  const config: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent.total) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );

        // Determine which file is being uploaded and update its progress
        if (rest.avatar && setAvatarProgress) {
          setAvatarProgress(percentCompleted);
        }
        if (rest.resume && setResumeProgress) {
          setResumeProgress(percentCompleted);
        }
      }
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const response = await API.Post<FormData, IPersonalInfo>(
    REST_SUB_URL.PROFILE_PERSONAL_INFO_STEP,
    formData,
    config,
  );

  if (response.status && response?.data) {
    toast.success(response.message);
    return response?.data;
  }
  toast.error(response.message);
  return false;
};
export const academicHistoryStep = async (
  payload: IAcademicHistory,
): Promise<IUser | boolean> => {
  const response = await API.Post<IAcademicHistory, IUser>(
    REST_SUB_URL.PROFILE_ACEDEMIC_HISTORY_STEP,
    payload,
  );
  if (response.status && response?.data) {
    toast.success(response.message);
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const trainingSeminarsStep = async (
  payload: ITrainingSeminar,
): Promise<IUser | boolean> => {
  const response = await API.Post<ITrainingSeminar, IUser>(
    REST_SUB_URL.PROFILE_TRAINING_SEMINARS_STEP,
    payload,
  );
  if (response.status && response?.data) {
    toast.success(response.message);
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const workExperiencesStep = async (
  payload: IWorkExperiences,
): Promise<IUser | boolean> => {
  const response = await API.Post<IWorkExperiences, IUser>(
    REST_SUB_URL.PROFILE_WORK_EXPERIENCE_STEP,
    payload,
  );
  if (response.status && response?.data) {
    toast.success(response.message);
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const specialSkills = async (
  payload: ISpecialSkills,
): Promise<IUser | boolean> => {
  const response = await API.Post<ISpecialSkills, IUser>(
    REST_SUB_URL.PROFILE_SPECIAL_SKILLS_STEP,
    payload,
  );
  if (response.status && response?.data) {
    toast.success(response.message);
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const profileOthersDataStep = async (
  payload: IFinalStepPayload,
): Promise<IUser | boolean> => {
  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    if (value instanceof FileList) {
      Array.from(value).forEach((file) => formData.append(key, file));
    } else {
      formData.append(key, value as string | Blob);
    }
  });
  const response = await API.Post<FormData, IUser>(
    REST_SUB_URL.PROFILE_OTHERS_INFO_STEP,
    formData,
  );
  if (response.status && response?.data) {
    toast.success(response.message);
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const getProfile = async (): Promise<IUser | boolean> => {
  const response = await API.Get<IUser>(`${REST_SUB_URL.CANDIDATE_PROFILE}`);

  if (response.status && response?.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};
