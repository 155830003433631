import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { IApplyJobData } from '@/@types/dashboard';
import FormField from '@/components/FormField';
import Modal from '@/components/Modal';
import { Button } from '@/components/ui/button';
import { CONTACT_FORM_FIELDS } from '@/constants/dashboard';
import { useGenericMutation } from '@/hooks/useMutationData';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { ROUTES } from '@/routes';
import { applyJob } from '@/services/dashboard';

export interface ContactInfoFormData {
  fullName: string;
  contactNumber: string;
  email: string;
  presentAddress: string;
}

interface Props {
  open: boolean;
  handleClose: () => void;
  jobId: string;
}

const ContactInfoModal: React.FC<Props> = ({ open, handleClose, jobId }) => {
  const staticText = strings.contactInfo;
  const { user } = useAuth();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactInfoFormData>({
    mode: 'all',
    defaultValues: {
      fullName: `${user?.firstName} ${user?.lastName}`,
      contactNumber: user?.personalInfo?.contactNo,
      email: user?.email,
      presentAddress: user?.personalInfo?.presentAddress,
    },
  });

  const applyJobMutation = useGenericMutation<IApplyJobData, boolean>(
    applyJob,
    {
      onSuccess: (response) => {
        if (response) {
          handleClose();
          navigate(ROUTES.DASHBOARD);
        }
      },
    },
  );
  const handleFormSubmit: SubmitHandler<ContactInfoFormData> = (formData) => {
    const payloadData: IApplyJobData = {
      jobId: jobId,
      payload: formData,
    };
    applyJobMutation.mutate(payloadData);
  };

  return (
    <Modal
      open={open}
      handleCloseModal={handleClose}
      title={staticText.addYourContactInformation}
    >
      <div className='space-y-4'>
        {CONTACT_FORM_FIELDS.map(({ name, title, placeholder, rules }) => (
          <Controller
            key={name}
            control={control}
            name={name as keyof ContactInfoFormData}
            rules={rules}
            render={({ field: { onChange, value } }) => (
              <FormField
                title={title}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
                disabled
                errors={errors}
                containerClassName='mt-2'
                labelClassName='font-extrabold md:text-base disable:bg-opacity-50'
              />
            )}
          />
        ))}
        <div className='flex justify-center gap-2 pt-3'>
          <Button variant='outline' onClick={handleClose} className='w-32'>
            {staticText.cancel}
          </Button>
          <Button
            onClick={handleSubmit(handleFormSubmit)}
            loading={applyJobMutation.status === 'pending'}
            disabled={applyJobMutation.status === 'pending'}
            className='w-32'
          >
            {staticText.apply}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ContactInfoModal;
