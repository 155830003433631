import toast from 'react-hot-toast';

import { ReviewPayload, ReviewResponse } from '@/@types/review';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';

export const postCompanyReview = async (
  payload: ReviewPayload,
): Promise<ReviewResponse | boolean> => {
  const response = await API.Post<ReviewPayload, ReviewResponse>(
    REST_SUB_URL.POST_COMPANY_REVIEW,
    payload,
  );

  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};
