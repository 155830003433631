import React, { useMemo, useState } from 'react';
import { CgDanger } from 'react-icons/cg';
import { useNavigate, useParams } from 'react-router-dom';

import { IJobs } from '@/@types/dashboard';
import BackHeader from '@/components/BackHeader';
import ConfirmationModal from '@/components/ConfirmationModal';
import Container from '@/components/Container';
import DownloadableFileRow from '@/components/DownloadableFileRow';
import NameAndDesc from '@/components/NameAndDesc';
import ProfileBadge from '@/components/ProfileBadge';
import StateIndicator from '@/components/StateIndicator';
import TooltipComponent from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import {
  createDetailNameAndValues,
  DOCUMENT_LIST,
} from '@/constants/dashboard';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { ROUTES } from '@/routes';
import {
  backoutCandidate,
  fetchJobDetail,
  getDocuments,
} from '@/services/dashboard';
import { hasAnyValue } from '@/utils/common';

import AttachmentPreview from '../Chat/ChatArea/AttachmentPreview';
import ContactInfoModal from '../Dashboard/components/ContactInfoModal';
import ReferFriendModal from '../Dashboard/components/ReferFriendModal';
import UploadFileModal from '../Dashboard/components/UploadFileModal';

const JobDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [isReferModalOpen, setIsReferModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [openUploadFileModal, setUploadFileModal] = useState(false);
  const [fileName, setFileName] = useState('');
  const { user } = useAuth();
  const navigate = useNavigate();

  const staticText = strings.JobsSection;

  const {
    data,
    isLoading,
    refetch: refetchDetails,
  } = useGenericQuery(['job-detail', id], () => fetchJobDetail(id!));
  const detail = data as IJobs;

  const handleUploadFilModalOpen = (
    e: { preventDefault: () => void },
    name: string,
  ) => {
    setUploadFileModal(true);
    setFileName(name);
    e.preventDefault();
  };

  const {
    data: documents,
    isLoading: loadingDocuments,
    refetch,
  } = useGenericQuery(['documents', id], () => getDocuments());

  const filledDocumentList = DOCUMENT_LIST.map((dummyDoc) => {
    const matchingDocument = Array.isArray(documents)
      ? documents.find((doc) => doc.name === dummyDoc.name)
      : null;
    return {
      ...dummyDoc,
      url: matchingDocument?.url || '',
      type: matchingDocument?.type || '',
    };
  });

  const filteredDocumentList = filledDocumentList
    ?.filter((item) => (detail?.requiredDocs ?? []).includes(item.name))
    .sort((a) => (a.url ? -1 : 1));

  const handleApplyOrBackoutButton = () => {
    if (detail?.isApplied && !detail?.candidateTags?.[0]?.backedOut) {
      setIsConfirmationModalOpen(true);
    } else {
      setIsContactModalOpen(true);
    }
  };

  const backoutMutation = useGenericMutation<string, boolean>(
    backoutCandidate,
    {
      onSuccess: (response) => {
        if (response) {
          setIsConfirmationModalOpen(false);
          refetchDetails();
        }
      },
    },
  );

  const handleBackout = () => {
    backoutMutation.mutate(detail?._id);
  };

  const buttonText = useMemo(() => {
    if (detail?.isApplied && !detail?.candidateTags?.[0]?.backedOut) {
      return staticText.backout;
    } else if (detail?.candidateTags?.[0]?.backedOut) {
      return staticText.reApply;
    }
    return staticText.apply;
  }, [detail]);

  return (
    <>
      <div>
        <div className='flex justify-between'>
          <BackHeader
            title={detail?.jobTitle || ''}
            loading={isLoading}
            handleBackBtn={() => navigate(ROUTES.DASHBOARD)}
          />
          <div className='flex gap-3'>
            <Button
              variant='outline'
              className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
              onClick={() => setIsReferModalOpen(true)}
            >
              {staticText.refer}
            </Button>
            <TooltipComponent
              align='center'
              className='py-2 px-4 text-xs mr-5'
              showTooltip={
                !hasAnyValue(user?.personalInfo) ||
                !hasAnyValue(user?.academicHistory) ||
                detail?.isHired
              }
              trigger={
                <Button
                  className='sm:w-44 md:w-32'
                  disabled={
                    !hasAnyValue(user?.personalInfo) ||
                    !hasAnyValue(user?.academicHistory) ||
                    isLoading ||
                    detail?.isHired
                  }
                  onClick={handleApplyOrBackoutButton}
                >
                  {buttonText}
                </Button>
              }
            >
              {detail?.isHired
                ? staticText.hired
                : staticText.tooltipForDisableButton}
            </TooltipComponent>
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-7 gap-4 mt-5'>
          <Container className='col-span-2 lg:col-span-5'>
            <div className='grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-5 gap-y-7'>
              <div>
                <Typography className='block font-bold md:text-[15px] mb-1'>
                  {staticText.name}
                </Typography>
                <ProfileBadge
                  name={`${detail?.authorDetails?.firstName} ${detail?.authorDetails?.lastName}`}
                  profilePicture={detail?.authorDetails?.avatar || ''}
                  className='justify-start'
                  isLoading={isLoading}
                />
              </div>
              {createDetailNameAndValues(detail).map(
                ({ title, description, className }, index) => {
                  if (
                    title === staticText.qualifications &&
                    ((data as IJobs)?.qualifications?.length ||
                      (data as IJobs)?.qualificationsDetails)
                  ) {
                    return (
                      <div className={className} key={index}>
                        <Typography
                          variant='heading'
                          className='md:text-[15px]'
                        >
                          {staticText.qualifications}
                        </Typography>
                        {isLoading ? (
                          <Skeleton className='w-20 h-4 bg-black/10' />
                        ) : (
                          <div className='flex gap-2 flex-col'>
                            {(data as IJobs)?.qualificationsDetails && (
                              <Typography>
                                {(data as IJobs)?.qualificationsDetails}
                              </Typography>
                            )}
                            <div className='flex flex-wrap gap-3'>
                              {(data as IJobs)?.qualifications?.map(
                                (attachment, index) => (
                                  <AttachmentPreview
                                    key={`attachment-${index}`}
                                    attachment={attachment as string}
                                  />
                                ),
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                  return (
                    <NameAndDesc
                      key={index}
                      title={title}
                      description={description}
                      isLoading={isLoading}
                      className={className}
                    />
                  );
                },
              )}
            </div>
          </Container>
          <Container className='col-span-1 lg:col-span-2'>
            <Typography variant='subheading' className='font-bold'>
              {staticText.requiredDocument}
            </Typography>
            <div className='mt-3 flex flex-col gap-y-2'>
              {loadingDocuments || isLoading ? (
                <div className='min-h-60 flex items-center justify-center'>
                  <StateIndicator state='Loading' />
                </div>
              ) : (
                <>
                  {filteredDocumentList.length === 0 ? (
                    <Typography className='mt-4 text-center text-mouseGrey'>
                      {strings.common.noData}
                    </Typography>
                  ) : (
                    filteredDocumentList
                      .sort((a) => (a.url ? -1 : 1))
                      .map((item) => (
                        <DownloadableFileRow
                          key={item.name}
                          name={item.name}
                          url={item.url}
                          onUploadIconClick={(e) => {
                            handleUploadFilModalOpen(e, item.name);
                          }}
                        />
                      ))
                  )}
                </>
              )}
            </div>
          </Container>
        </div>
      </div>
      <ReferFriendModal
        handleClose={() => setIsReferModalOpen(false)}
        open={isReferModalOpen}
      />
      <ContactInfoModal
        open={isContactModalOpen}
        handleClose={() => setIsContactModalOpen(false)}
        jobId={id!}
      />
      <UploadFileModal
        open={openUploadFileModal}
        handleCloseModal={() => setUploadFileModal(false)}
        fileName={fileName}
        refetch={refetch}
      />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        handleCloseModal={() => setIsConfirmationModalOpen(false)}
        primaryBtnLabel={staticText.backout}
        primaryBtnAction={handleBackout}
        textDesc={staticText.backoutText}
        titleClassName='h-20 bg-red-200'
        isBackoutModal
        isLoading={backoutMutation.isPending}
        icon={<CgDanger className='size-6 text-tomatoRed' />}
      />
    </>
  );
};

export default JobDetails;
