import toast from 'react-hot-toast';

import { IPaginationResponse } from '@/@types';
import {
  INotification,
  INotificationsCountResponse,
} from '@/@types/notification';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';

export const getNotifications = async (
  type: string,
  page: string,
): Promise<IPaginationResponse<INotification> | boolean> => {
  const response = await API.Get<IPaginationResponse<INotification>>(
    `${REST_SUB_URL.GET_ALL_NOTIFICATIONS}?type=${type}&page=${page}`,
  );

  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const markNotificationRead = async (
  notificationId: string,
): Promise<boolean> => {
  const response = await API.Patch<undefined, boolean>(
    `${REST_SUB_URL.MARK_NOTIFICATION_READ}/${notificationId}`,
  );

  if (response.status && response?.data) {
    return true;
  }
  toast.error(response.message);
  return false;
};

export const markAllNotificationsRead = async (): Promise<boolean> => {
  const response = await API.Patch<undefined, boolean>(
    REST_SUB_URL.MARK_ALL_NOTIFICATIONS_READ,
  );

  if (response.status && response?.data) {
    return true;
  }
  toast.error(response.message);
  return false;
};

export const getUnreadNotificationsCount = async (): Promise<
  INotificationsCountResponse | boolean
> => {
  const response = await API.Get<INotificationsCountResponse>(
    `${REST_SUB_URL.GET_UN_READ_NOTIFICATIONS_COUNT}`,
  );

  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const clearUnreadNotificationsCount = async (): Promise<boolean> => {
  const response = await API.Patch<undefined, boolean>(
    REST_SUB_URL.CLEAR_UN_READ_NOTIFICATIONS_COUNT,
  );
  if (response.status) {
    return true;
  }
  toast.error(response.message);
  return false;
};
