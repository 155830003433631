import React from 'react';

import { strings } from '@/locales';

const LineForOrOtion = () => {
  return (
    <div className='flex justify-center items-center gap-2'>
      <div className='h-[1px] w-full bg-lightGrey' />
      <div className='text-primaryBlack'>{strings.or}</div>
      <div className='h-[1px] w-full bg-lightGrey' />
    </div>
  );
};

export default LineForOrOtion;
