import React, { useState } from 'react';

import { IJobs } from '@/@types/dashboard';
import { ReviewPayload, ReviewResponse } from '@/@types/review';
import Rating from '@/assets/svgs/Rating';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { useGenericMutation } from '@/hooks/useMutationData';
import { strings } from '@/locales';
import { postCompanyReview } from '@/services/review';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  open: boolean;
  handleClose: () => void;
  company?: IJobs;
}

const ReviewModal: React.FC<Props> = ({ open, handleClose, company }) => {
  const staticText = strings.dashboard.reviewModal;

  const queryClient = useQueryClient();

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const postReviewMutation = useGenericMutation<
    ReviewPayload,
    ReviewResponse | boolean
  >(postCompanyReview, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['job-list'],
      });
      handleClose();
    },
  });

  const handlePostReview = () => {
    const payload = {
      comment,
      rating,
      ticket: company!._id!,
      company: company!.companyDetails!._id!,
    };
    postReviewMutation.mutate(payload);
  };

  return (
    <Modal open={open} handleCloseModal={handleClose} title={staticText.title}>
      <>
        <Typography
          variant='subheading'
          className='md:text-base'
        >{`${staticText.ratingPrompt} '${company?.companyDetails?.name}'?`}</Typography>
        <Rating length={5} rating={rating} handleRating={setRating} />
        <Typography variant='subheading' className='md:text-base'>
          {staticText.comment}
        </Typography>
        <Textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={staticText.write}
          className='rounded-xl w-full placeholder:text-mouseGrey placeholder:font-medium placeholder:text-sm text-sm md:text-sm font-medium text-primaryBlack min-h-48 p-4 bg-smoke border-none'
        />
        <Button
          className='text-base'
          onClick={handlePostReview}
          disabled={!rating}
          loading={postReviewMutation.status === 'pending'}
        >
          {staticText.submit}
        </Button>
      </>
    </Modal>
  );
};

export default ReviewModal;
