import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IJobs } from '@/@types/dashboard';
import Container from '@/components/Container';
import Tags from '@/components/Tags';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { CANDIDATE_STATUS } from '@/constants/candidate';
import {
  createJobCardDetailsDashboard,
  DASHBOARD_TABS,
} from '@/constants/dashboard';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { getTagVariant } from '@/utils/common';
import { getLocalDate } from '@/utils/dateTime';

import ReviewModal from './ReviewModal';

interface IJobCard {
  data?: IJobs;
  activeTab: DASHBOARD_TABS;
  isLoading?: boolean;
  className?: string;
}

const JobCard: React.FC<IJobCard> = ({
  data,
  activeTab,
  isLoading,
  className,
}) => {
  const staticText = strings.JobsSection;
  const navigate = useNavigate();

  const [openReviewModal, setOpenReviewModal] = useState(false);

  const handleClick = () => {
    if (data?._id) {
      navigate(`${location.pathname}/${data._id}`);
    }
  };
  const JOB_CARD_DETAILS = createJobCardDetailsDashboard(data);

  const getStatusText = () => {
    switch (true) {
      case activeTab === DASHBOARD_TABS.HIRED ||
        data?.candidateTags?.[0]?.status === CANDIDATE_STATUS.HR_ORIENTATION:
        return staticText.hired;
      case data?.candidateTags?.[0]?.backedOut:
        return staticText.backedOut;
      default:
        return data?.candidateTags?.[0]?.status;
    }
  };

  return (
    <>
      {openReviewModal ? (
        <ReviewModal
          open={openReviewModal}
          handleClose={() => setOpenReviewModal(false)}
          company={data}
        />
      ) : null}
      <Container
        className={cn(
          'md:px-4 md:py-4 flex flex-col items-center cursor-pointer justify-between h-full border-none shadow-4-side-small',
          className,
        )}
        onClick={handleClick}
      >
        <div className='w-full'>
          {isLoading ? (
            <div className='-mt-0.5 mb-2 w-full flex justify-between my-1'>
              <Skeleton className='w-24 h-3.5' />
              <Skeleton className='w-24 h-3.5' />
            </div>
          ) : (
            <div className='-mt-0.5 mb-1 w-full flex justify-between items-center'>
              <Typography className='text-xs md:text-xs w-full'>
                {getLocalDate(data?.updatedAt, 'MMM D, YYYY')}
              </Typography>
              {data?.candidateTags?.[0]?.status ? (
                <div className='h-6 min-w-fit'>
                  <Tags
                    text={getStatusText()}
                    containerClassName='h-6 w-fit'
                    textClassName='text-xs md:text-xs font-semibold'
                    variant={getTagVariant(getStatusText())}
                    isLoading={isLoading}
                  />
                </div>
              ) : null}
            </div>
          )}
          <div className='flex justify-between'>
            {isLoading ? (
              <Skeleton className='w-32 h-6 my-2' />
            ) : (
              <Typography className='text-lg md:text-xl font-bold text-primary my-1'>
                {data?.jobTitle}
              </Typography>
            )}
          </div>
          {isLoading ? (
            <div className='flex gap-y-1 flex-col'>
              {Array.from({ length: 5 }).map((_, index) => (
                <React.Fragment key={`skeleton-${index}`}>
                  <Skeleton className='w-32 h-3.5 my-1 mx-1' />
                </React.Fragment>
              ))}
            </div>
          ) : (
            <div className='flex  flex-col w-full space-y-1 mb-1'>
              {JOB_CARD_DETAILS.map((detail, index) => (
                <Typography key={index} className='md:text-sm font-semibold'>
                  <span className='font-bold'>{detail.label}:</span>{' '}
                  {detail.value}
                </Typography>
              ))}
            </div>
          )}
          <div className='mt-2 h-12 overflow-hidden'>
            {isLoading ? (
              <>
                <Skeleton className='w-full h-4 mb-1' />
                <Skeleton className='w-3/4 h-4' />
              </>
            ) : (
              <Typography className='md:text-sm'>
                <span className='font-bold'>{staticText.description}:</span>{' '}
                {data?.jobDescription}
              </Typography>
            )}
          </div>
          <div className='flex justify-end'>
            {activeTab === DASHBOARD_TABS.HIRED && !data?.isReviewed ? (
              <Button
                className='md:p-0 h-fit w-fit md:text-sm'
                variant='link'
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenReviewModal(true);
                }}
                loading={isLoading}
              >
                {staticText.writeReview}
              </Button>
            ) : null}
          </div>
        </div>
      </Container>
    </>
  );
};

export default JobCard;
