import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { IUser } from '@/@types/auth';
import { IAcademicHistory } from '@/@types/profile';
import Container from '@/components/Container';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { ACADEMIC_HISTORY_FIELDS } from '@/constants/profile';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useFormContext } from '@/provider/FormProvider/index';
import { academicHistoryStep } from '@/services/profile';
import { hasAnyValue } from '@/utils/common';

const AcademicHistory = () => {
  const { profile: staticText, common } = strings;
  const { stepTwo, setData, handleNext, handleBack } = useFormContext();
  const { user, setUser } = useAuth();
  const [isEdit, setIsEdit] = useState(false);

  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
    watch,
    reset,
    setValue,
  } = useForm<IAcademicHistory>({
    mode: 'all',
    defaultValues: stepTwo || {},
  });

  const postPersonalInfoMutation = useGenericMutation<
    IAcademicHistory,
    IUser | boolean
  >(academicHistoryStep, {
    onSuccess: (result) => {
      if (typeof result !== 'boolean') {
        setData({
          step: 2,
          data: result?.academicHistory?.[0] as IAcademicHistory,
        });
        handleNext();
        setUser({ ...user, academicHistory: result?.academicHistory } as IUser);
      }
    },
  });

  const onSubmit: SubmitHandler<IAcademicHistory> = (data) => {
    if (
      isDirty &&
      Object.keys(dirtyFields).some((fieldName) => {
        return !!data[fieldName as keyof IAcademicHistory];
      })
    ) {
      postPersonalInfoMutation.mutate(data);
    } else {
      handleNext();
    }
  };

  useEffect(() => {
    if (user?.academicHistory) {
      const educationLevels: Array<{
        key: keyof IAcademicHistory;
        index: number;
      }> = [
        { key: 'college', index: 0 },
        { key: 'vocational', index: 1 },
        { key: 'highSchool', index: 2 },
        { key: 'elementary', index: 3 },
      ];
      educationLevels.forEach(({ key, index }) => {
        const historyEntry = user?.academicHistory?.[index];
        if (historyEntry) {
          setValue(key, historyEntry[key] || '');
          setValue(
            `${key}YearCovered` as keyof IAcademicHistory,
            historyEntry.yearCovered || '',
          );
          setValue(
            `${key}Course` as keyof IAcademicHistory,
            historyEntry.course || '',
          );
        }
      });
    }
  }, [user, reset]);

  const collegeFields = watch([
    'college',
    'collegeYearCovered',
    'collegeCourse',
  ]);

  const vocationalFields = watch([
    'vocational',
    'vocationalYearCovered',
    'vocationalCourse',
  ]);

  const highSchoolFields = watch([
    'highSchool',
    'highSchoolYearCovered',
    'highSchoolCourse',
  ]);

  const elementaryFields = watch([
    'elementary',
    'elementaryYearCovered',
    'elementaryCourse',
  ]);

  const isSectionFilled = (fields: (string | number)[]) =>
    fields.some((value) => !!value); // Check if any field in the section has a value

  return (
    <>
      <Container className='rounded-t-none border-t-0 p-4 w-[95%] mx-auto'>
        <div className='mt-10 md:mt-20'>
          <div className='flex justify-between'>
            <Typography variant='heading' className='md:text-2xl'>
              {staticText.academicHistory}
            </Typography>
            {hasAnyValue(user?.academicHistory) ? (
              <Button
                variant='link'
                className={cn('w-auto h-auto p-1 text-base', {
                  'text-tomatoRed': isEdit,
                })}
                onClick={() => setIsEdit(!isEdit)}
              >
                {isEdit ? strings.common.cancel : strings.common.edit}
              </Button>
            ) : null}
          </div>
          <div className='md:grid md:grid-cols-3 gap-x-4 mt-4'>
            {ACADEMIC_HISTORY_FIELDS.map((field, index) => (
              <div key={`${field.name}_${index}`}>
                <Controller
                  control={control}
                  name={field.name as keyof IAcademicHistory}
                  rules={{
                    validate: (value) => {
                      const isCollegeSectionFilled =
                        isSectionFilled(collegeFields);
                      const isVocationalSectionFilled =
                        isSectionFilled(vocationalFields);

                      if (field.name.includes('college')) {
                        return (
                          !!value ||
                          (isCollegeSectionFilled
                            ? `${field.title} is required`
                            : `${field.title} is required`)
                        );
                      }

                      if (field.name.includes('vocational')) {
                        return (
                          !!value ||
                          (isVocationalSectionFilled
                            ? `${field.title} is required`
                            : `${field.title} is required`)
                        );
                      }

                      // Validate high school and elementary based on their sections being filled
                      if (
                        (field.name.includes('highSchool') &&
                          isSectionFilled(highSchoolFields)) ||
                        (field.name.includes('elementary') &&
                          isSectionFilled(elementaryFields))
                      ) {
                        return !!value || `${field.title} is required`;
                      }

                      return true;
                    },
                  }}
                  render={({ field: { onChange, value, name } }) => (
                    <FormField
                      title={field.title}
                      placeholder={common.typeHere}
                      name={name}
                      value={value}
                      onChange={onChange}
                      errors={errors}
                      labelClassName='font-bold md:text-base mb-3'
                      isRequired={
                        field.name.includes('college') ||
                        field.name.includes('vocational')
                      }
                      disabled={!isEdit && hasAnyValue(user?.academicHistory)}
                      type={field.isNumber ? 'number' : 'text'}
                    />
                  )}
                />
              </div>
            ))}
          </div>
        </div>
      </Container>
      <div className='w-[95%] mx-auto py-3 flex justify-end'>
        <div className='flex gap-3'>
          <Button
            variant='outline'
            className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
            onClick={handleBack}
            disabled={postPersonalInfoMutation.status === 'pending'}
          >
            {staticText.buttons.back}
          </Button>
          <Button
            className='sm:w-44 md:w-32'
            disabled={postPersonalInfoMutation.status === 'pending'}
            loading={postPersonalInfoMutation.status === 'pending'}
            onClick={handleSubmit(onSubmit)}
          >
            {staticText.buttons.next}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AcademicHistory;
