import * as React from 'react';
import { SVGProps } from 'react';
const ChatIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    viewBox='0 0 27 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20.2133 12.0805V16.8898C20.2133 17.2024 20.2013 17.503 20.1652 17.7916C19.8887 21.0379 17.977 22.649 14.4541 22.649H13.9732C13.6726 22.649 13.384 22.7933 13.2037 23.0337L11.7609 24.9575C11.1237 25.8111 10.0896 25.8111 9.4524 24.9575L8.00959 23.0337C7.85328 22.8293 7.50463 22.649 7.24011 22.649H6.75919C2.92374 22.649 1 21.6991 1 16.8898V12.0805C1 8.55764 2.62316 6.64593 5.85744 6.36939C6.146 6.33332 6.44658 6.32129 6.75919 6.32129H14.4541C18.2896 6.32129 20.2133 8.24503 20.2133 12.0805Z'
      stroke={props.color || '#1B1A1B'}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M25.0246 7.27115V12.0805C25.0246 15.6153 23.4014 17.515 20.1671 17.7916C20.2032 17.503 20.2152 17.2024 20.2152 16.8898V12.0805C20.2152 8.24504 18.2915 6.3213 14.4561 6.3213H6.76113C6.44852 6.3213 6.14794 6.33333 5.85938 6.3694C6.13591 3.13512 8.04762 1.51196 11.5705 1.51196H19.2654C23.1008 1.51196 25.0246 3.43571 25.0246 7.27115Z'
      stroke={props.color || '#1B1A1B'}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.8223 15.0384H14.8331'
      stroke={props.color || '#1B1A1B'}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.6114 15.0384H10.6222'
      stroke={props.color || '#1B1A1B'}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.40435 15.0384H6.41517'
      stroke={props.color || '#1B1A1B'}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default ChatIcon;
