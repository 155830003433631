import React, { useEffect, useState } from 'react';

import Container from '@/components/Container';
import SearchBar from '@/components/SearchBar';
import { Typography } from '@/components/Typography';
import useDebounce from '@/hooks/useDebounce';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { useChatContext } from '@/provider/ChatProvider';
import { getChatList } from '@/services/chat';

import ChatAccordion from './chatAccordion';

const ChatSidebar = () => {
  const staticText = strings.chatScreen;

  const { setChatList } = useChatContext();

  const [searchText, setSearchText] = useState('');

  const debouncedSearchText = useDebounce(searchText);

  const { data, isLoading } = useGenericQuery(
    ['chatList', debouncedSearchText],
    () => getChatList(true, debouncedSearchText),
  );

  useEffect(() => {
    if (typeof data === 'object') setChatList(data);
  }, [data]);

  return (
    <Container width='w-full lg:w-2/6' className='flex flex-col gap-4'>
      <div className='flex justify-between xs:items-center flex-col xs:flex-row'>
        <Typography variant='heading' className='md:text-2xl'>
          {staticText.chat}
        </Typography>
      </div>
      <SearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        placeholder={staticText.sidebarSearchPlaceholder}
      />
      <div className='flex flex-col h-full gap-2 overflow-scroll scrollbarHidden'>
        <ChatAccordion loading={isLoading} />
      </div>
    </Container>
  );
};

export default ChatSidebar;
