import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface IDrawerContext {
  drawerExpand: boolean;
  setDrawerExpand: React.Dispatch<React.SetStateAction<boolean>>;
  drawerClosing: boolean;
  setDrawerClosing: React.Dispatch<React.SetStateAction<boolean>>;
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  isSmallScreen: boolean | null;
  showNotifications: boolean;
  setShowNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  unreadNotificationsCount: number;
  setUnreadNotificationsCount: React.Dispatch<React.SetStateAction<number>>;
}

const DrawerContext = createContext<IDrawerContext>({
  drawerExpand: true,
  setDrawerExpand: () => {},
  drawerClosing: false,
  setDrawerClosing: () => {},
  currentTab: '',
  setCurrentTab: () => {},
  isSmallScreen: null,
  showNotifications: false,
  setShowNotifications: () => {},
  unreadNotificationsCount: 0,
  setUnreadNotificationsCount: () => {},
});

interface IDrawerProvider {
  children: React.ReactNode;
}

export const DrawerProvider = ({ children }: IDrawerProvider) => {
  const location = useLocation();
  const [, pathSegment] = location.pathname.split('/');
  const isSmallScreen = window.innerWidth < 768;

  const [showNotifications, setShowNotifications] = useState(false);
  const [currentTab, setCurrentTab] = useState(pathSegment || 'dashboard');
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [drawerClosing, setDrawerClosing] = useState(false);
  const [drawerExpand, setDrawerExpand] = useState(!isSmallScreen);

  useEffect(() => {
    setCurrentTab(pathSegment || 'dashboard');
  }, [pathSegment]);

  const value = {
    currentTab,
    drawerExpand,
    drawerClosing,
    setDrawerClosing,
    setCurrentTab,
    setDrawerExpand,
    isSmallScreen,
    showNotifications,
    setShowNotifications,
    unreadNotificationsCount,
    setUnreadNotificationsCount,
  };

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export const useDrawerContext = () => useContext(DrawerContext);
