import toast from 'react-hot-toast';

import { IPaginationResponse } from '@/@types';
import { IChatResponse, IMessage } from '@/@types/chat';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';
import { buildQueryParams } from '@/utils/common';
import { QueryClient } from '@tanstack/react-query';

export const getChatList = async (
  isRecruiter?: boolean,
  search?: string,
): Promise<IChatResponse | boolean> => {
  const queryParams = buildQueryParams({
    isRecruiter,
    search,
    isCompanyClient: !isRecruiter,
  });

  const response = await API.Get<IChatResponse>(
    `${REST_SUB_URL.GET_CHAT_LIST}?${queryParams.toString()}`,
  );
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const getChatById = async (
  conversationID: string,
  page: string,
): Promise<IPaginationResponse<IMessage> | boolean> => {
  const response = await API.Get<IPaginationResponse<IMessage>>(
    `${REST_SUB_URL.GET_CHAT}/${conversationID}?page=${page}`,
  );

  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const readChatMessages = async (
  conversationId: string,
  queryClient: QueryClient,
): Promise<boolean> => {
  const response = await API.Get<boolean>(
    `${REST_SUB_URL.READ_MESSAGES}/${conversationId}`,
  );
  if (response.status) {
    queryClient.invalidateQueries({
      queryKey: ['chatList'],
    });
    return true;
  }
  toast.error(response.message);
  return false;
};
