import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { GoBell, GoBellFill, GoHome, GoHomeFill } from 'react-icons/go';
import { HiDownload } from 'react-icons/hi';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useLocation, useNavigate } from 'react-router-dom';

import { IDropdownOption } from '@/@types';
import { IUser } from '@/@types/auth';
import { ChatIcon } from '@/assets/svgs';
import ChatFillIcon from '@/assets/svgs/ChatFillIcon';
import { HEADER_DROPDOWN_OPTIONS, PROFILE_DROPDOWN_OPTIONS } from '@/constants';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import Notifications from '@/pages/Notifications';
import { useAuth } from '@/provider/AuthProvider';
import { useChatContext } from '@/provider/ChatProvider';
import { useDrawerContext } from '@/provider/DrawerProvider';
import { ROUTES } from '@/routes';
import { clearUnreadNotificationsCount } from '@/services/notifications';
import { snakeToTitleCase } from '@/utils/common';
import { clearStorage } from '@/utils/localstorage';

import Logo from '../assets/images/logo.svg';

import { Button } from './ui/button';
import ReactDropdown from './Dropdown';
import ProfileBadge from './ProfileBadge';
import UnreadBadge from './UnreadBadge';

const Header = () => {
  const notificationRef = useRef<HTMLDivElement>(null);
  const bellIconRef = useRef<HTMLButtonElement>(null);
  const { user, setUser, setToken } = useAuth();
  const { unReadMessageCount } = useChatContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeIcon, setActiveIcon] = useState('');

  const staticText = strings.chatScreen;

  const {
    setDrawerExpand,
    drawerExpand,
    isSmallScreen,
    showNotifications,
    setShowNotifications,
    unreadNotificationsCount,
    setUnreadNotificationsCount,
  } = useDrawerContext();

  const notificationsVisible =
    (isSmallScreen && location.pathname.includes(ROUTES.NOTIFICATIONS)) ||
    showNotifications;

  const userInfo = useMemo(() => {
    return user || ({} as IUser);
  }, [user]);

  const { lastName, firstName, personalInfo, profileType } = userInfo;

  const handleNavigationClick = () => {
    if (unreadNotificationsCount) {
      clearUnreadNotifications.mutate(null);
    }
    const toggleNotifications = () => setShowNotifications((prev) => !prev);

    if (!notificationsVisible) {
      isSmallScreen ? navigate(ROUTES.NOTIFICATIONS) : toggleNotifications();
    } else {
      isSmallScreen ? navigate(-1) : toggleNotifications();
    }
  };

  const clearUnreadNotifications = useGenericMutation<null, boolean>(
    clearUnreadNotificationsCount,
    {
      onSuccess: (response) => {
        if (response) {
          setUnreadNotificationsCount(0);
        }
      },
    },
  );

  const isActiveTab = (route: string) => {
    if (notificationsVisible) {
      return false; // When notifications are visible, no navigation tab should be active
    }
    const isActive = location.pathname.includes(route);
    if (isActive && activeIcon !== route) {
      setActiveIcon(route);
    }
    return isActive;
  };

  const handleProfileOptionChange = (
    option: IDropdownOption | null | string,
  ) => {
    switch (option) {
      case HEADER_DROPDOWN_OPTIONS.PROFILE:
        navigate(ROUTES.PROFILE);
        break;
      case HEADER_DROPDOWN_OPTIONS.MY_DOCUMENTS:
        navigate(ROUTES.MY_DOCUMENTS);
        break;
      case HEADER_DROPDOWN_OPTIONS.LOGOUT:
        window.location.href = ROUTES.LOGIN;
        clearStorage();
        setUser(null);
        setToken(null);
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        showNotifications &&
        !notificationRef.current?.contains(event.target as Node) &&
        !bellIconRef.current?.contains(event.target as Node)
      ) {
        handleNavigationClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNotifications]);

  // Update activeIcon when location changes
  useEffect(() => {
    if (location.pathname.includes(ROUTES.DASHBOARD)) {
      setActiveIcon(ROUTES.DASHBOARD.split(':')[0]!);
    } else if (location.pathname.includes(ROUTES.CHAT)) {
      setActiveIcon(staticText.chat);
    }
  }, [location.pathname]);

  return (
    <div className='relative'>
      <div className='flex justify-between items-center w-full px-4 md:px-8 h-[70px] bg-white border-b-greyWhite border-b'>
        <div className='flex items-center'>
          <div
            className='w-14 cursor-pointer'
            onClick={() => navigate(ROUTES.DASHBOARD)}
          >
            <img src={Logo} alt='Logo' />
          </div>
          <Button
            variant='ghost'
            size='icon'
            icon={
              <HiDownload className='-rotate-90 text-primaryBlack text-xl' />
            }
            className={cn('size-8 ', {
              hidden: drawerExpand || isSmallScreen,
            })}
            onClick={() => setDrawerExpand((prev) => !prev)}
          />
        </div>
        <div className='flex gap-4 md:gap-5 justify-between items-center'>
          <Button
            variant='outline'
            size='icon'
            icon={
              isActiveTab(ROUTES.DASHBOARD.split(':')[0]!) ? (
                <GoHomeFill className='text-primary text-xl' />
              ) : (
                <GoHome className='text-primaryBlack text-lg' />
              )
            }
            className='border-greyWhite rounded-[5px] bg-simplyViolet size-8 border'
            onClick={() => navigate(ROUTES.DASHBOARD)}
          />
          <div className='flex'>
            <Button
              variant='outline'
              size='icon'
              icon={
                isActiveTab(ROUTES.CHAT) ? (
                  <ChatFillIcon />
                ) : (
                  <ChatIcon width={18} />
                )
              }
              className='border-greyWhite rounded-[5px] bg-simplyViolet size-8 border'
              onClick={() => navigate(ROUTES.CHAT)}
            />
            {unReadMessageCount > 0 ? (
              <UnreadBadge count={unReadMessageCount} className='-ml-2 -mt-1' />
            ) : null}
          </div>
          <div className='flex'>
            <Button
              ref={bellIconRef}
              variant='outline'
              size='icon'
              icon={
                notificationsVisible ? (
                  <GoBellFill className='text-primary text-xl' />
                ) : (
                  <GoBell className='text-primaryBlack text-lg' />
                )
              }
              className='border-greyWhite rounded-[5px] bg-simplyViolet size-8 border'
              onClick={handleNavigationClick}
            />
            {unreadNotificationsCount ? (
              <UnreadBadge
                count={unreadNotificationsCount}
                className='-ml-2 -mt-1'
              />
            ) : null}
          </div>

          <RxHamburgerMenu
            className='md:hidden cursor-pointer'
            onClick={() => setDrawerExpand(true)}
          />
          {!isSmallScreen ? (
            <ReactDropdown
              options={PROFILE_DROPDOWN_OPTIONS}
              onChange={handleProfileOptionChange}
              className='min-w-40 z-20'
              inputHeight='0px'
              inputWidth='0px'
              menuWidth='200px'
              valueHide={true}
              customButton={
                <Button className='bg-white flex justify-between text-left px-0 py-0 h-auto'>
                  <ProfileBadge
                    name={`${firstName} ${lastName}`}
                    nameClassName='truncate w-24'
                    profilePicture={
                      (personalInfo?.avatar || user?.avatar) as string
                    }
                    attribute={snakeToTitleCase(profileType?.toLowerCase())}
                  />
                  <FaChevronDown className=' text-black ' />
                </Button>
              }
            />
          ) : null}
        </div>
      </div>
      {showNotifications ? (
        <div
          ref={notificationRef}
          className={cn({
            'fixed right-48 top-14 z-50': !isSmallScreen,
          })}
        >
          <Notifications />
        </div>
      ) : null}
    </div>
  );
};

export default Header;
