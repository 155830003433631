import React from 'react';

import { IResponsePercentage, IStatisticsPercentage } from '@/@types/dashboard';
import Container from '@/components/Container';
import CustomPieChart from '@/components/CustomPieChart';
import { Typography } from '@/components/Typography';
import {
  appliedJobDetail,
  responsePercentageDetail,
} from '@/constants/dashboard';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import {
  getJobAppliedPercentage,
  getResponsePercentage,
} from '@/services/dashboard';

import JobsSection from './components/Jobs';
import ProfileStatus from './components/ProfileStatus';

const Dashboard = () => {
  const staticText = strings.dashboard;

  const { data: appliedJobPercentage, isLoading: appliedJobLoading } =
    useGenericQuery(['applied-job-percentage'], () =>
      getJobAppliedPercentage(),
    );

  const { data: responsePercentage, isLoading: responsePercentageLoading } =
    useGenericQuery(['response-percentage'], () => getResponsePercentage());

  return (
    <Container className='md:px-5 md:py-6 xl:grid xl:grid-cols-3 gap-5 space-y-5 xl:space-y-0 min-h-[calc(100vh-100px)]'>
      <div className='col-span-2 flex flex-col gap-5 h-full'>
        <ProfileStatus />
        <div className='flex-grow overflow-hidden'>
          <JobsSection />
        </div>
      </div>
      <div className='flex flex-col md:flex-row xl:flex-col gap-5'>
        <Container className='gap-3 flex flex-col justify-between w-full md:w-1/2 xl:w-full'>
          <div className='flex flex-col gap-5'>
            <Typography className='font-bold block' variant='xl'>
              {staticText.jobsApplied}
            </Typography>
            <Typography className='md:text-sm'>
              {staticText.jobsAppliedDesc}
            </Typography>
          </div>
          <div className='w-full flex flex-col justify-center items-center'>
            <CustomPieChart
              data={appliedJobDetail(
                appliedJobPercentage as IStatisticsPercentage,
              )}
              outerRadius={120}
              innerRadius={60}
              isHalf={true}
              height={190}
              width={350}
              isLoading={appliedJobLoading}
            />
          </div>
        </Container>
        <Container className='flex flex-col justify-between w-full md:w-1/2 xl:w-full'>
          <div className='gap-6 flex flex-col'>
            <Typography className='font-bold block' variant='xl'>
              {staticText.response}
            </Typography>
            <Typography className='md:text-sm'>
              {staticText.responseDesc}
            </Typography>
          </div>
          <div className='w-full flex flex-col justify-center items-center'>
            <CustomPieChart
              data={responsePercentageDetail(
                responsePercentage as IResponsePercentage,
              )}
              outerRadius={100}
              innerRadius={55}
              height={250}
              width={250}
              isHalf={false}
              isLoading={responsePercentageLoading}
            />
          </div>
        </Container>
      </div>
    </Container>
  );
};

export default Dashboard;
