import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FiSearch } from 'react-icons/fi';

import { cn } from '@/lib/utils';

import { Input } from './ui/input';
import { Separator } from './ui/seperator';
import Container from './Container';

interface Props {
  placeholder?: string;
  searchIcon?: React.ReactNode;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  button?: React.ReactNode;
  separator?: boolean;
  containerClassName?: string;
  inputFieldClassName?: string;
}

const SearchBar: React.FC<Props> = ({
  placeholder = 'Search...',
  searchIcon = <FiSearch className='mr-1 text-quickSilver flex-shrink-0' />,
  searchText,
  setSearchText,
  button,
  separator = false,
  containerClassName,
  inputFieldClassName,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenTextRef = useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const inputWidth = inputRef.current?.offsetWidth || 0;
    const textWidth = hiddenTextRef.current?.offsetWidth || 0;

    setIsOverflowing(textWidth > inputWidth);
  }, [placeholder]);

  return (
    <Container
      height='h-11'
      className={cn(
        'bg-simplyViolet flex items-center md:px-2 md:py-0 py-0 overflow-hidden relative',
        containerClassName,
      )}
    >
      {searchIcon}
      <div className='w-full relative ml-1'>
        {/* Only animate the placeholder if it's overflowing */}
        <div className='placeholder-wrapper'>
          {searchText.length ? null : (
            <span
              className={cn(
                isOverflowing ? 'placeholder-text' : 'text-xs text-quickSilver',
              )}
            >
              {placeholder}
            </span>
          )}
        </div>
        <Input
          ref={inputRef}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className={cn(
            'bg-simplyViolet border-none text-sm font-semibold p-0',
            inputFieldClassName,
          )}
        />
        {/* Hidden span to measure text width */}
        <span ref={hiddenTextRef} className='hidden-text text-xs'>
          {placeholder}
        </span>
      </div>
      {separator && <Separator orientation='vertical' className='h-8 m-2' />}
      {button}
    </Container>
  );
};

export default SearchBar;
