import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { FiFile, FiUploadCloud } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';

import { cn } from '@/lib/utils';
import { strings } from '@/locales';

import { Button } from './ui/button';
import { Input } from './ui/input';
import Container from './Container';
import { Typography } from './Typography';

interface Props {
  buttonText?: string;
  iconClassName?: string;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  fileSize?: number;
  acceptedFileFormat?: string[] | string;
  dropzoneClassName?: string;
  uploadingDivClassName?: string;
  fileName?: string;
  progress?: number;
  isResume?: boolean;
}

const mimeTypes: Record<string, string> = {
  '.pdf': 'application/pdf',
  '.docx':
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.doc': 'application/msword',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png',
  '.csv': 'text/csv',
};

const getMimeTypes = (extensions: string[] | string): string[] => {
  if (Array.isArray(extensions)) {
    return extensions.map((ext) => mimeTypes[ext] || '');
  }
  return [mimeTypes[extensions] || ''];
};

const FileDropContainer: React.FC<Props> = ({
  buttonText,
  iconClassName,
  setFile,
  fileSize = 5,
  acceptedFileFormat = '.docx',
  dropzoneClassName,
  uploadingDivClassName,
  fileName,
  progress = 0,
}) => {
  const { common } = strings;
  const FILE_SIZE_LIMIT = fileSize * 1024 * 1024;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadedFileSize, setUploadedFileSize] = useState(0);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles[0]) {
        const [file] = acceptedFiles;
        setUploadedFileSize(Number((file.size / 1024 / 1024).toFixed(3)));

        if (file.size > FILE_SIZE_LIMIT) {
          toast.error(common.fileSizeError);
        } else {
          setSelectedFile(file);
          setFile(file);
        }
      }
    },
    [FILE_SIZE_LIMIT, setFile, common.fileSizeError],
  );

  const acceptConfig = useMemo(() => {
    const mimeTypesArray = getMimeTypes(
      Array.isArray(acceptedFileFormat)
        ? acceptedFileFormat
        : [acceptedFileFormat],
    );

    return mimeTypesArray.reduce(
      (acc, mimeType) => {
        if (mimeType) {
          acc[mimeType] = Object.keys(mimeTypes).filter(
            (ext) => mimeTypes[ext] === mimeType,
          );
        }
        return acc;
      },
      {} as Record<string, string[]>,
    );
  }, [acceptedFileFormat]);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: acceptConfig,
  });

  const handleClearFileUpload = () => {
    setFile(null);
    setSelectedFile(null);
  };

  const isImage = (file: File | null) => file?.type.startsWith('image/');

  const renderFilePreview = () => (
    <div
      className={cn(
        'mt-1 h-44 flex gap-2 justify-center items-center bg-simplyViolet rounded-xl cursor-pointer focus:outline-none',
        { 'h-24': buttonText },
        dropzoneClassName,
      )}
    >
      {isImage(selectedFile) ? (
        <img
          src={URL.createObjectURL(selectedFile!)}
          alt={selectedFile?.name}
          className='size-12 object-cover rounded-lg'
        />
      ) : (
        <div className='p-2 rounded-full bg-primary'>
          <FiFile className='text-2xl text-white' />
        </div>
      )}

      <Typography className='md:text-sm font-semibold truncate max-w-full'>
        {fileName && fileName.length > 30
          ? `${fileName.slice(0, 30)}...`
          : fileName}
      </Typography>

      <div
        className='p-1 rounded-full bg-tomatoRed/15'
        onClick={handleClearFileUpload}
      >
        <HiOutlineTrash className='text-tomatoRed text-base' />
      </div>
    </div>
  );

  const renderFileDropArea = () => (
    <div
      {...getRootProps()}
      className={cn(
        'mt-1 h-44 flex flex-col justify-center items-center bg-simplyViolet rounded-xl cursor-pointer drag-area focus:outline-none',
        { 'flex-row gap-2 h-24': buttonText },
        dropzoneClassName,
      )}
      onClick={open}
    >
      <Input {...getInputProps()} />
      <FiUploadCloud className={cn('text-3xl text-mouseGrey', iconClassName)} />
      {!buttonText && (
        <Typography className='font-bold text-mouseGrey'>
          {common['drag&drop']}
        </Typography>
      )}
      <Typography className='md:text-sm text-medium text-mouseGrey flex items-center w-fit'>
        {!buttonText ? common.or : ''}
        <Button variant='link' className='p-0 m-1 w-fit h-8' type='button'>
          {buttonText || common.browseFile}
        </Button>
        {!buttonText ? common.fromDevice : ''}
      </Typography>
    </div>
  );

  const renderProgressView = () => (
    <Container height='h-fit' className={cn('mt-1', uploadingDivClassName)}>
      <Typography className='md:text-sm font-semibold truncate max-w-full'>
        {fileName && fileName.length > 30
          ? `${fileName.slice(0, 30)}...`
          : fileName}
      </Typography>
      <div className='flex items-center gap-2'>
        <Typography className='md:text-sm font-semibold text-quickSilver'>
          {Math.min(Math.max(progress, 0), 100)}%
        </Typography>
        <div className='size-1 rounded-full bg-quickSilver' />
        <Typography className='md:text-sm font-semibold text-quickSilver'>
          {progress < 100
            ? `${Math.ceil(((100 - progress) / 100) * 30)} seconds remaining`
            : common.uploadComplete}
        </Typography>
      </div>
      <div className='mt-4 h-2 overflow-hidden rounded-full bg-quickSilver/60'>
        <div
          className='h-5 transition-all ease-in-out bg-primary'
          style={{ width: `${Math.min(Math.max(progress, 0), 100)}%` }}
        />
      </div>
    </Container>
  );

  if (progress !== 0) return renderProgressView();
  if (!selectedFile) return renderFileDropArea();
  return renderFilePreview();
};

export default FileDropContainer;
