import toast from 'react-hot-toast';

import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';

import {
  ForgotPasswordEmailField,
  IGoogleVerifyResponse,
  ILoginRequest,
  ILoginResponse,
  IOtpFields,
  IResetPasswordFields,
  ISignUpFields,
  ISignUpResponse,
  IUser,
  OtpFieldsResponseType,
  ResendVerificationEmail,
} from '../@types/auth';

export const signup = async (
  payload: ISignUpFields,
): Promise<string | boolean> => {
  const response = await API.Post<ISignUpFields, ISignUpResponse>(
    REST_SUB_URL.SIGNUP,
    payload,
  );

  if (response.status && response?.data) {
    return response?.message;
  }
  toast.error(response.message);
  return false;
};

export const googleVerifySignup = async (): Promise<IGoogleVerifyResponse> => {
  const response = await API.Post<undefined, IGoogleVerifyResponse>(
    REST_SUB_URL.GOOGLE_VERIFY,
  );

  if (response.status && response?.data) {
    return response.data;
  }
  throw new Error(response.message);
};

export const verifySignupEmail = async (
  payload: IOtpFields,
): Promise<OtpFieldsResponseType> => {
  const response = await API.Post<IOtpFields, OtpFieldsResponseType>(
    REST_SUB_URL.VERIFY_EMAIL,
    payload,
  );
  if (response.status && response?.data) {
    return response.data;
  }
  throw new Error(response.message);
};

export const resendVerifyEmail = async (
  payload: ResendVerificationEmail,
): Promise<boolean> => {
  const response = await API.Post<ResendVerificationEmail, boolean>(
    REST_SUB_URL.RESEND_VERIFICATION_EMAIL,
    payload,
  );

  if (response.status) {
    return true;
  }
  throw new Error(response.message);
};

export const login = async (
  payload: ILoginRequest,
): Promise<ILoginResponse | boolean> => {
  const response = await API.Post<ILoginRequest, ILoginResponse>(
    REST_SUB_URL.LOGIN,
    payload,
  );
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const forgotPassword = async (
  payload: ForgotPasswordEmailField,
): Promise<IUser | boolean> => {
  const response = await API.Post<ForgotPasswordEmailField, IUser | boolean>(
    REST_SUB_URL.FORGOT_PASSWORD,
    payload,
  );
  if (response.status && response?.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const verifyOtp = async (
  payload: IOtpFields,
): Promise<OtpFieldsResponseType> => {
  const response = await API.Post<IOtpFields, OtpFieldsResponseType>(
    REST_SUB_URL.VERIFY_RESET_PASSWORD,
    payload,
  );
  if (response.status && response?.data) {
    return response.data;
  }
  throw new Error(response.message);
};

export const resetPassword = async (
  payload: IResetPasswordFields,
): Promise<string | boolean> => {
  const response = await API.Post<IResetPasswordFields, boolean>(
    REST_SUB_URL.RESET_PASSWORD,
    payload,
  );
  if (response.status) {
    return response.message;
  }
  toast.error(response.message);
  return false;
};
