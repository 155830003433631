/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import {
  components,
  GroupBase,
  MenuPlacement,
  OptionProps as ReactSelectOptionProps,
  OptionsOrGroups,
  SingleValueProps,
} from 'react-select';
import Creatable from 'react-select/creatable';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';

import { IDropdownOption } from '@/@types';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { getTagVariant } from '@/utils/common';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import { CustomStyles, customStyles } from './customStyle';
import ProfileBadge from './ProfileBadge';
import Tags from './Tags';
import { Typography } from './Typography';

export interface DropdownProps {
  title?: string;
  options: IDropdownOption[] | string[];
  defaultValue?: string | IDropdownOption;
  value?: IDropdownOption | null | string;
  placeholder?: string;
  isLoading?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  labelClassName?: string;
  creatable?: boolean;
  isClearable?: boolean;
  onChange?: (selectedOption: string | IDropdownOption) => void;
  className?: string;
  name?: string;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  haveMoreOptions?: boolean;
  inputHeight?: string;
  inputWidth?: string;
  menuWidth?: string;
  valueHide?: boolean;
  tagsDropdown?: boolean;
  errors?: FieldErrors;
  borderRadius?: string;
  icon?: React.ReactNode;
  setInputSearch?: React.Dispatch<React.SetStateAction<string>>;
  saveBothLabelAndValue?: boolean;
  menuPlacement?: MenuPlacement;
  customButton?: React.ReactNode;
}

const ReactDropdown: React.FC<DropdownProps> = ({
  title,
  defaultValue,
  options,
  value: propValue = null,
  placeholder,
  isSearchable = false,
  isDisabled = false,
  isLoading = false,
  isRequired = false,
  labelClassName,
  creatable = true,
  name,
  className,
  onChange,
  haveMoreOptions = false,
  setPage,
  inputHeight = '48px',
  inputWidth = '100%',
  valueHide = false,
  tagsDropdown = false,
  borderRadius = '6px',
  errors,
  setInputSearch,
  saveBothLabelAndValue = false,
  menuPlacement = 'auto',
  customButton,
  menuWidth,
}) => {
  // if string array in options then convert it to value label object
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const normalizedOptions: IDropdownOption[] = Array.isArray(options)
    ? options.map((opt) =>
        typeof opt === 'string' ? { label: opt, value: opt } : opt,
      )
    : [];
  // for showing placeholder if value is empty string
  const convertedValue =
    typeof propValue === 'string'
      ? (normalizedOptions.find((option) => option.value === propValue) ?? null)
      : propValue;

  const customStylesWithHeight: CustomStyles = {
    ...customStyles,
    control: (base) => {
      const borderColor = customButton
        ? 'transparent' // Always transparent if customButton is present
        : '#E4E6E9'; // Use '#E4E6E9' when not focused and no customButton

      return {
        ...base,
        'minHeight': inputHeight,
        'maxHeight': inputHeight,
        'minWidth': inputWidth,
        'display': valueHide ? 'none' : 'flex',
        borderColor,
        '&:hover': {
          borderColor,
        },
        borderRadius,
      };
    },
    option: (provided, { isFocused }) => ({
      ...provided,
      'cursor': 'pointer',
      'background': isFocused ? '#2B8ABC' : '#fff',
      'color': isFocused ? '#fff' : '#333333',
      '&:hover': {
        backgroundColor: '#2B8ABC',
        color: '#fff',
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: menuWidth || '100%',
      borderRadius: '12px',
      overflow: 'hidden',
      right: 0,
    }),
  };

  const getSelectValue = () => {
    switch (true) {
      case valueHide:
        return '';
      case !convertedValue?.value:
        return null;
      default:
        return convertedValue;
    }
  };
  const getPlaceholder = (): string => {
    switch (true) {
      case customButton:
        return '';
      case isSearchable:
        return placeholder || strings.common.typeHere;

      default:
        return strings.common.select;
    }
  };

  const CustomOption: React.FC<
    ReactSelectOptionProps<IDropdownOption | string>
  > = (props) => {
    const renderOptionContent = () => {
      if (tagsDropdown) {
        return (
          <Tags
            text={typeof props === 'object' ? props.label : props}
            variant={getTagVariant(props.label)}
            containerClassName='py-1 text-xs h-auto min-w-20 text-sm'
          />
        );
      }
      if (
        typeof options[0] === 'object' &&
        'avatar' in (options[0] as IDropdownOption)
      ) {
        return (
          <ProfileBadge
            name={props.label}
            className='justify-start'
            avatarClassName='size-7 text-[10px]'
            profilePicture={(props.data as IDropdownOption)?.avatar}
          />
        );
      }
      if (typeof props.data === 'object' && 'icon' in props.data) {
        return (
          <div className='flex items-center capitalize hover:text-white'>
            {props.data.icon && (
              <span
                className={`mr-2 ${props.label === strings.sidebarRoutes.logout ? 'text-tomatoRed' : 'text-current'}`}
              >
                {props.data.icon}
              </span>
            )}
            <span
              className={`${props.label === strings.sidebarRoutes.logout ? 'text-tomatoRed' : null}`}
            >
              {props.label}
            </span>
          </div>
        );
      }
      return props.label;
    };
    return (
      <div>
        <components.Option {...props}>
          {renderOptionContent()}
        </components.Option>
      </div>
    );
  };

  const SingleValue = ({
    children,
    ...props
  }: SingleValueProps<IDropdownOption | string>) => {
    let content;
    if (tagsDropdown) {
      content = (
        <Tags
          text={convertedValue?.label}
          variant={getTagVariant(convertedValue?.label)}
          containerClassName='py-1 text-xs h-auto min-w-20 text-sm mx-5'
        />
      );
    } else if ('avatar' in (convertedValue as IDropdownOption)) {
      content = (
        <ProfileBadge
          name={convertedValue?.label || ''}
          className='justify-start'
          profilePicture={convertedValue?.avatar}
          avatarClassName='size-7 text-[10px]'
        />
      );
    } else if (convertedValue && (convertedValue as IDropdownOption).icon) {
      content = (
        <div className='flex items-center'>
          {convertedValue.icon && (
            <span className='mr-2'>{convertedValue.icon}</span>
          )}
          <span>{convertedValue?.label}</span>
        </div>
      );
    } else {
      content = children;
    }
    return (
      <components.SingleValue {...props}>{content}</components.SingleValue>
    );
  };

  const onChangeValue = (selected: IDropdownOption) => {
    if (onChange) {
      onChange(saveBothLabelAndValue ? selected : selected?.value);
    }
    setIsOpen(false);
  };

  const loadMore = () => {
    if (haveMoreOptions) {
      setPage?.((prev) => prev + 1);
    }
  };

  const handleInputChange = (input: string) => {
    setInputSearch?.(input);
  };

  const loadOptions: LoadOptions<
    string | IDropdownOption,
    GroupBase<string | IDropdownOption>,
    unknown
  > = (
    _search: string,
    prevOptions: OptionsOrGroups<
      string | IDropdownOption,
      GroupBase<string | IDropdownOption>
    >,
  ) => {
    const filteredOptions = normalizedOptions
      .slice(prevOptions.length, prevOptions.length + 10)
      .map((option) =>
        typeof option === 'string'
          ? { label: option, value: option }
          : { label: option.label, value: option.value, icon: option.icon },
      );

    return {
      options: filteredOptions as OptionsOrGroups<
        string | IDropdownOption,
        GroupBase<string | IDropdownOption>
      >,
      hasMore: true,
    };
  };

  const commonProps = {
    classNamePrefix: 'select',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styles: customStylesWithHeight as any,
    placeholder: getPlaceholder(),
    value: getSelectValue(),
    isDisabled,
    isLoading,
    isSearchable,
    menuPlacement,
    maxMenuHeight: 150,
    onChange: onChangeValue as never,
    onMenuScrollToBottom: loadMore,
    components: {
      SingleValue,
      Option: CustomOption,
      IndicatorSeparator: () => null,
    },
    onInputChange: handleInputChange,
    autoFocus: false,
    controlShouldRenderValue: true,
    menuIsOpen: customButton ? isOpen : undefined,
  };
  return (
    <div>
      {title ? (
        <Typography
          className={cn(
            'flex md:text-sm capitalize font-semibold my-2',
            labelClassName,
          )}
        >
          {title}
          {isRequired && <span className='text-redColor text-xl ml-1'>*</span>}
        </Typography>
      ) : null}
      <div className={cn('relative', className)} ref={dropdownRef}>
        {customButton ? (
          <div onClick={toggleDropdown}>{customButton}</div>
        ) : null}
        <div
          className={cn('relative', { 'absolute z-10 w-full': customButton })}
        >
          {creatable ? (
            <Creatable
              key={convertedValue?.value}
              options={normalizedOptions}
              isValidNewOption={() => false}
              {...commonProps}
            />
          ) : (
            <AsyncPaginate
              key={convertedValue?.value}
              className={className}
              cacheUniqs={[JSON.stringify(normalizedOptions)]}
              defaultValue={
                typeof defaultValue === 'string'
                  ? { label: defaultValue, value: defaultValue }
                  : defaultValue
              }
              loadOptions={loadOptions}
              defaultOptions={true}
              {...commonProps}
            />
          )}
        </div>
      </div>
      {errors && (
        <HookFormErrorMessage
          errors={errors}
          name={String(name)}
          render={({ message }) => (
            <p className='text-redColor text-xs'>{message}</p>
          )}
        />
      )}
    </div>
  );
};

export default ReactDropdown;
