import React, { useEffect, useMemo } from 'react';
import { FaCheck } from 'react-icons/fa';

import { Typography } from '@/components/Typography';
import { STEPPER_STEPS } from '@/constants/profile';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useFormContext } from '@/provider/FormProvider';
import { hasAnyValue } from '@/utils/common';

const itemsWidths = 100 / 6;

const Stepper = () => {
  const { common } = strings;
  const { currentStep, skippedSteps, setCurrentStep } = useFormContext();
  const [stepsCompleted, setStepsCompleted] = React.useState(1);
  const { user } = useAuth();

  const isStepSkipped = (stepNumber: number) =>
    skippedSteps.includes(stepNumber);

  const filteredOthers = useMemo(() => {
    return Object.fromEntries(
      Object.entries(user?.others || {}).filter(([key]) => {
        return key !== 'documents';
      }),
    );
  }, [user?.others]);

  const filterePersonalInfo = useMemo(() => {
    return Object.fromEntries(
      Object.entries(user?.personalInfo || {}).filter(([key]) => {
        return key !== 'avatar';
      }),
    );
  }, [user?.personalInfo]);

  useEffect(() => {
    if (!user) return;
    if (hasAnyValue(filterePersonalInfo) || currentStep === 1) {
      setStepsCompleted(1);
    }
    if (hasAnyValue(user?.academicHistory) || currentStep === 2) {
      setStepsCompleted(2);
    }
    if (hasAnyValue(user?.trainingSeminars) || currentStep === 3) {
      setStepsCompleted(3);
    }
    if (hasAnyValue(user?.workExperiences) || currentStep === 4) {
      setStepsCompleted(4);
    }
    if (
      user?.skills?.length ||
      user?.specialSkills?.length ||
      currentStep === 5
    ) {
      setStepsCompleted(5);
    }
    if (hasAnyValue(filteredOthers) || currentStep === 6) {
      setStepsCompleted(6);
    }
  }, [user, currentStep]);

  const handleStepClick = (stepNumber: number) => () => {
    if (stepNumber <= stepsCompleted) {
      setCurrentStep(stepNumber);
    }
  };

  return (
    <ul className='mt-4 mx-auto w-10/12 flex flex-row'>
      {STEPPER_STEPS.map((step, index) => {
        const stepNumber = index + 1;
        return (
          <li
            key={stepNumber}
            style={{ width: `${itemsWidths * 100}%` }}
            className={cn('relative flex items-center shrink basis-0', {
              'flex-1': stepNumber !== STEPPER_STEPS.length,
              'text-primary': currentStep >= stepNumber,
              'text-gray-600': currentStep < stepNumber,
            })}
          >
            <div className='flex flex-col items-center absolute -top-1.5 -left-5 md:-left-14 lg:-left-16'>
              <div
                className={cn(
                  'flex justify-center items-center rounded-full p-[1px] size-4 border-[3px]',
                  {
                    'bg-primary text-white':
                      currentStep > stepNumber && !isStepSkipped(stepNumber),
                    'bg-gray-100 text-gray-800':
                      currentStep <= stepNumber || isStepSkipped(stepNumber),
                    'border-primary':
                      currentStep >= stepNumber ||
                      stepsCompleted + 1 === stepNumber,
                    'cursor-pointer border-primary bg-primary':
                      stepNumber <= stepsCompleted,
                  },
                )}
                onClick={
                  stepNumber <= stepsCompleted
                    ? handleStepClick(stepNumber)
                    : undefined
                }
              >
                {stepNumber <= stepsCompleted ||
                (currentStep > stepNumber && !isStepSkipped(stepNumber)) ? (
                  <FaCheck className='text-white' />
                ) : null}
              </div>
              <div className='md:w-32 lg:w-36 text-center'>
                <Typography className='text-sm font-medium mt-2'>
                  <span>{common.step}</span> {stepNumber}
                </Typography>
                <Typography className='text-sm font-semibold mt-2 hidden md:block'>
                  {step}
                </Typography>
              </div>
            </div>
            {STEPPER_STEPS.length !== stepNumber && (
              <div
                className={cn('w-full h-[3px]', {
                  'bg-primary':
                    stepNumber <= stepsCompleted || stepNumber === currentStep,
                  'bg-gray-200': stepNumber > stepsCompleted,
                })}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Stepper;
