import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { HiMiniArrowTopRightOnSquare } from 'react-icons/hi2';
import PhoneInput from 'react-phone-input-2';
import { City, Country } from 'country-state-city';

import { IDropdownOption } from '@/@types';
import { IUser } from '@/@types/auth';
import { IPersonalInfo } from '@/@types/profile';
import PDFIcon from '@/assets/svgs/pdfIcon.svg';
import Container from '@/components/Container';
import DatePickerField from '@/components/DatePickerField';
import ReactDropdown from '@/components/Dropdown';
import FileDropContainer from '@/components/FileDropContainer';
import FormField from '@/components/FormField';
import ProfileBadge from '@/components/ProfileBadge';
import StateIndicator from '@/components/StateIndicator';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { INPUT_ERRORS, USER_PROVIDER } from '@/constants';
import {
  createPersonalInfoData,
  generateProfileFields,
  LOCAL_RECRUITMENT_ACTIVITY_SOURCE_DROPDOWN_OPTIONS,
  PLATFORM_SOURCE_DROPDOWN_OPTIONS,
  RECRUITMENT_SOURCE,
} from '@/constants/profile';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useCommonDataContext } from '@/provider/CommonDataProvider';
import { useFormContext } from '@/provider/FormProvider/index';
import { personalInfoStep } from '@/services/profile';
import { getAge, getFileNameFromUrl, hasAnyValue } from '@/utils/common';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import 'react-phone-input-2/lib/style.css';

const PersonalInfo = () => {
  const { profile: staticText, common } = strings;
  const [searchCountry, setSearchCountry] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const { stepOne, setData, handleNext, profileLoading } = useFormContext();
  const [updateResumeUrl, setUpdateResumeUrl] = useState(false);

  const { user, setUser } = useAuth();

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [sourceList, setSourceList] = useState<IDropdownOption[]>();
  const previousSourceType = useRef<string | undefined>(undefined);
  const [formKey, setFormKey] = useState(0);

  const countryList = Country.getAllCountries()
    .filter((country) =>
      country.name.toLowerCase().includes(searchCountry.toLowerCase()),
    )
    .map((item) => ({
      label: item.name,
      value: item.isoCode,
    }));

  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    watch,
    setValue,
    handleSubmit,
    reset,
  } = useForm<IPersonalInfo>({
    mode: 'all',
    defaultValues: user?.personalInfo
      ? createPersonalInfoData(user, countryList)
      : stepOne || {},
  });

  const birthDate = watch('birthDate');
  const age = watch('age');
  const avatar = watch('avatar');
  const resume = watch('resume');
  const sourceType = watch('sourceType');
  const country = watch('country');

  const cityList = City.getCitiesOfCountry((country as IDropdownOption)?.value)
    ?.filter((city) =>
      city.name.toLowerCase().includes(searchCity.toLowerCase()),
    )
    ?.map((item) => ({
      label: item.name,
      value: item.name,
    }));

  const [avatarProgress, setAvatarProgress] = useState(0);
  const [resumeProgress, setResumeProgress] = useState(0);

  const handleAvatarProgress = useCallback((percentage: number) => {
    setAvatarProgress(percentage);
  }, []);

  const handleResumeProgress = useCallback((percentage: number) => {
    setResumeProgress(percentage);
  }, []);

  useEffect(() => {
    if (country) {
      setValue('city', '');
      setFormKey((prev) => prev + 1);
    }
  }, [country]);

  useEffect(() => {
    if (user?.personalInfo?.city || (country as IDropdownOption)?.value) {
      const cityName = cityList?.find(
        (item) => item.value === user?.personalInfo?.city,
      )?.label;
      setValue('city', cityName || '');
    }
  }, [setValue, country]);

  useEffect(() => {
    let updatedSourceList: IDropdownOption[] = [];
    if (sourceType === RECRUITMENT_SOURCE.PLATFORM) {
      updatedSourceList = PLATFORM_SOURCE_DROPDOWN_OPTIONS;
    } else if (sourceType === RECRUITMENT_SOURCE.LRA) {
      updatedSourceList = LOCAL_RECRUITMENT_ACTIVITY_SOURCE_DROPDOWN_OPTIONS;
    } else {
      updatedSourceList = [];
    }
    setSourceList(updatedSourceList);
    // Set 'source' value only if 'sourceType' has changed from the previous value
    if (
      previousSourceType.current !== undefined &&
      previousSourceType.current !== sourceType
    ) {
      setValue('source', '');
    }
    // Update previous sourceType for the next render
    previousSourceType.current = sourceType as string;
    setFormKey((prev) => prev + 1);
  }, [sourceType]);

  const {
    department,
    haveMoreDepartmentPage,
    loadingDepartments,
    setDepartmentPage,
    setDepartmentSearch,
    religions,
    loadingReligions,
    setDepartment,
  } = useCommonDataContext();

  const PERSONAL_INFO_FIELDS = generateProfileFields(
    department,
    loadingDepartments,
    haveMoreDepartmentPage,
    setDepartmentPage,
    setDepartmentSearch,
    religions?.map((item) => ({
      label: item.name,
      value: item.name,
    })),
    loadingReligions,
    sourceList,
    sourceType ? false : true,
    countryList,
    cityList,
    setSearchCountry,
    setSearchCity,
    country ? false : true,
    searchCountry,
    searchCity,
  );

  const postPersonalInfoMutation = useGenericMutation<
    IPersonalInfo,
    IPersonalInfo | boolean
  >(personalInfoStep, {
    onSuccess: (result) => {
      if (typeof result !== 'boolean') {
        setUser({
          ...user,
          avatar: result?.avatar || user?.avatar,
          personalInfo: {
            ...result,
            avatar: result?.avatar || user?.avatar,
          } as IPersonalInfo,
        } as IUser);
        if (isEdit) {
          setData({ step: 1, data: result });
          setIsEdit(false);
        }
        reset();
      }
    },
  });

  const onSubmit: SubmitHandler<IPersonalInfo> = (data) => {
    setAvatarProgress(0);
    setResumeProgress(0);
    const {
      nameOfSpouse,
      occupationOfSpouse,
      nameOfFather,
      occupationOfFather,
      nameOfMother,
      occupationOfMother,
      positionDesired,
      ...restData
    } = data;
    const payload = {
      ...restData,
      familyEducationDetails: JSON.stringify([
        { nameOfSpouse, occupation: occupationOfSpouse },
        { nameOfFather, occupation: occupationOfFather },
        { nameOfMother, occupation: occupationOfMother },
      ]),
      positionDesired: JSON.stringify(positionDesired),
      department:
        (data?.department as IDropdownOption)?.value ||
        (user?.personalInfo?.department as string) ||
        '',
      source: (data?.source as IDropdownOption)?.value || data?.source,
      sourceType:
        (data?.sourceType as IDropdownOption)?.value || data?.sourceType,
      country: (data?.country as IDropdownOption)?.label || data?.country,
      city: (data?.city as IDropdownOption)?.label || data?.city,
      setAvatarProgress: handleAvatarProgress,
      setResumeProgress: handleResumeProgress,
    };
    if (
      isDirty &&
      Object.keys(dirtyFields).some((fieldName) => {
        return !!data[fieldName as keyof IPersonalInfo];
      })
    ) {
      postPersonalInfoMutation.mutate(payload as IPersonalInfo);
    } else {
      handleNext();
      setData({ step: 1, data: payload });
    }
  };

  useEffect(() => {
    if (birthDate && !user?.personalInfo?.age) {
      setValue('age', getAge(birthDate as string));
    }
  }, [birthDate, age]);

  useEffect(() => {
    if (avatar instanceof Blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(avatar);
    } else {
      setPreviewUrl(avatar || null);
    }
  }, [avatar]);

  useEffect(() => {
    if (user?.personalInfo?.department) {
      setDepartment([
        {
          label: user?.personalInfo?.department,
          value: user?.personalInfo?.department,
        } as IDropdownOption,
        ...department,
      ]);
    }
  }, []);

  const filterePersonalInfo = useMemo(() => {
    return Object.fromEntries(
      Object.entries(user?.personalInfo || {}).filter(([key]) => {
        return key !== 'avatar';
      }),
    );
  }, [user?.personalInfo]);

  return (
    <>
      <Container className='rounded-t-none border-t-0 p-4 w-[95%] mx-auto min-h-96'>
        <div className='mt-10 md:mt-20'>
          <div className='flex justify-between'>
            <Typography variant='heading' className='md:text-2xl'>
              {staticText.personalInformation}
            </Typography>
            {hasAnyValue(filterePersonalInfo) && !profileLoading ? (
              <Button
                variant='link'
                className={cn('w-auto h-auto p-1 text-base', {
                  'text-tomatoRed': isEdit,
                })}
                onClick={() => setIsEdit(!isEdit)}
              >
                {isEdit ? strings.common.cancel : strings.common.edit}
              </Button>
            ) : null}
          </div>
          {profileLoading ? (
            <StateIndicator state='Loading' />
          ) : (
            <>
              <div className='grid md:grid-cols-5 gap-x-4 mt-4 items-end'>
                <div className='flex gap-3 items-center flex-col md:flex-row col-span-5 relative lg:col-span-2'>
                  <ProfileBadge
                    name={`${user?.firstName} ${user?.lastName}`}
                    profilePicture={
                      (user?.avatar ||
                        user?.personalInfo?.avatar ||
                        previewUrl) as string
                    }
                    avatarClassName='size-24'
                  />
                  {isEdit ||
                  !user?.avatar ||
                  !user?.personalInfo?.avatar ||
                  (user?.provider === USER_PROVIDER.GOOGLE &&
                    !hasAnyValue(filterePersonalInfo)) ? (
                    <Controller
                      name='avatar'
                      control={control}
                      render={({ field: { onChange } }) => (
                        <FileDropContainer
                          setFile={onChange}
                          fileName={
                            typeof avatar === 'object' ? avatar?.name : ''
                          }
                          dropzoneClassName='w-full h-28 p-2'
                          uploadingDivClassName='w-full h-28'
                          acceptedFileFormat={['.png', '.jpeg']}
                          progress={
                            typeof watch('avatar') === 'object'
                              ? 0
                              : avatarProgress
                          }
                        />
                      )}
                    />
                  ) : null}
                </div>
                <div className='col-span-5 lg:col-span-3'>
                  {(user?.personalInfo?.resume as string)?.length && isEdit ? (
                    <div className='flex justify-end mb-1 items-center'>
                      {updateResumeUrl ? (
                        <Button
                          variant='link'
                          className='w-fit p-0 md:p-0 h-fit no-underline text-tomatoRed'
                          onClick={() => setUpdateResumeUrl(false)}
                        >
                          {common.cancel}
                        </Button>
                      ) : (
                        <Button
                          variant='link'
                          className='w-fit p-0 md:p-0 h-fit no-underline'
                          onClick={() => setUpdateResumeUrl(true)}
                        >
                          {staticText?.updateResume}
                        </Button>
                      )}
                    </div>
                  ) : null}
                  {isEdit ||
                  !user?.personalInfo?.resume ||
                  (user?.provider === USER_PROVIDER.GOOGLE &&
                    !hasAnyValue(filterePersonalInfo)) ? (
                    <Controller
                      name='resume'
                      rules={{ required: INPUT_ERRORS.RESUME.required }}
                      control={control}
                      render={({ field: { onChange, name } }) => (
                        <>
                          <FileDropContainer
                            buttonText={staticText.uploadResume}
                            setFile={onChange}
                            fileName={
                              typeof resume === 'object' ? resume?.name : ''
                            }
                            dropzoneClassName='w-full h-28 p-2'
                            uploadingDivClassName='w-full h-28'
                            iconClassName='bg-primary rounded-full text-white text-[40px] p-2'
                            acceptedFileFormat={['.pdf']}
                            isResume
                            progress={
                              typeof watch('resume') === 'object'
                                ? 0
                                : resumeProgress
                            }
                          />
                          {errors && (
                            <HookFormErrorMessage
                              errors={errors}
                              name={name}
                              render={({ message }) => (
                                <p className='text-redColor text-xs'>
                                  {message}
                                </p>
                              )}
                            />
                          )}
                        </>
                      )}
                    />
                  ) : (
                    <div className='border-greyWhite mt-5 lg:mt-0 border h-full justify-between w-full flex items-center p-3 sm:px-4 sm:py-5 bg-simplyViolet rounded-xl'>
                      {user?.personalInfo?.resume ? (
                        <>
                          <div className='flex gap-3 items-center'>
                            <img
                              src={PDFIcon}
                              alt='resume'
                              className='h-8 w-7'
                            />
                            <Typography className='font-bold'>
                              {getFileNameFromUrl(
                                user?.personalInfo?.resume as string,
                              )}
                            </Typography>
                          </div>
                          <a
                            href={user?.personalInfo?.resume as string}
                            download
                            target='_blank'
                            rel='noreferrer'
                            className='justify-end'
                          >
                            <Button
                              variant='ghost'
                              size='icon'
                              icon={
                                <HiMiniArrowTopRightOnSquare className='text-lg' />
                              }
                              className='bg-white rounded-md'
                            />
                          </a>
                        </>
                      ) : (
                        <Typography className='text-center w-full'>
                          {common.resumeError}
                        </Typography>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className='md:grid md:grid-cols-3 gap-x-4 mt-4'>
                {PERSONAL_INFO_FIELDS.map((field, index) => (
                  <React.Fragment key={`${field.name}_${index}`}>
                    <div
                      className={cn({
                        [`col-span-${field.colSpan}`]: field.colSpan,
                      })}
                    >
                      <Controller
                        control={control}
                        name={field.name as keyof IPersonalInfo}
                        rules={{ required: field.isRequiredMessage }}
                        render={({ field: { onChange, value, name } }) => {
                          switch (true) {
                            case field.isDropdown:
                              return (
                                <ReactDropdown
                                  key={formKey}
                                  name={name}
                                  title={field?.title}
                                  placeholder={common.typeHere}
                                  options={field?.dropdownOptions || []}
                                  value={value as IDropdownOption}
                                  onChange={onChange}
                                  className='w-full mt-2 h-12 rounded-md'
                                  labelClassName='font-bold md:text-base mb-3'
                                  errors={errors}
                                  isRequired={field?.isRequired}
                                  isDisabled={
                                    (!isEdit &&
                                      hasAnyValue(filterePersonalInfo)) ||
                                    field?.isDisabled
                                  }
                                  saveBothLabelAndValue={
                                    field?.props?.saveBothLabelAndValue
                                  }
                                  isLoading={field?.isLoading || false}
                                  isSearchable={field?.props?.isSearchable}
                                  setInputSearch={field?.props?.setInputSearch}
                                  haveMoreOptions={
                                    field?.props?.haveMoreOptions as boolean
                                  }
                                  setPage={field?.props?.setPage}
                                />
                              );
                            case field.isDate:
                              return (
                                <div>
                                  <Typography className='flex capitalize font-bold md:text-base mb-3 mt-2'>
                                    {field.title}
                                    {field.isRequired ? (
                                      <span className='text-redColor text-xl ml-1'>
                                        *
                                      </span>
                                    ) : null}
                                  </Typography>
                                  <DatePickerField
                                    value={value as Date}
                                    setValue={onChange}
                                    contanierClassName='w-full rounded-md h-12'
                                    name={name}
                                    errors={errors}
                                    disabled={
                                      !isEdit &&
                                      hasAnyValue(filterePersonalInfo)
                                    }
                                    isAgeField={field.name === 'birthDate'}
                                  />
                                </div>
                              );
                            case field.contactNumber:
                              return (
                                <div>
                                  <Typography className='flex capitalize font-bold md:text-base mb-3 mt-2'>
                                    {field.title}
                                    {field.isRequired ? (
                                      <span className='text-redColor text-xl ml-1'>
                                        *
                                      </span>
                                    ) : null}
                                  </Typography>
                                  <PhoneInput
                                    country={'ph'}
                                    enableSearch
                                    value={value as string}
                                    onChange={(phoneValue) => {
                                      onChange(phoneValue);
                                    }}
                                    inputProps={{
                                      name: 'phone',
                                      required: true,
                                      autoFocus: true,
                                    }}
                                    disabled={
                                      !isEdit &&
                                      hasAnyValue(filterePersonalInfo)
                                    }
                                    inputClass='!h-12 text-base disabled:!bg-greyWhite !w-full !border-greyWhite'
                                    containerClass='h-12'
                                  />
                                  {errors && (
                                    <HookFormErrorMessage
                                      errors={errors}
                                      name={String(name)}
                                      render={({ message }) => (
                                        <p className='text-redColor text-xs'>
                                          {message}
                                        </p>
                                      )}
                                    />
                                  )}
                                </div>
                              );
                            default:
                              return (
                                <FormField
                                  title={field.title}
                                  placeholder={common.typeHere}
                                  name={name}
                                  value={value as keyof IPersonalInfo | number}
                                  onChange={onChange}
                                  errors={errors}
                                  labelClassName='font-bold md:text-base mb-3'
                                  isRequired={field.isRequired}
                                  type={field.isNumber ? 'number' : 'text'}
                                  isHeightOrWeight={
                                    field.name === 'height' ||
                                    field.name === 'weight'
                                  }
                                  disabled={
                                    !isEdit && hasAnyValue(filterePersonalInfo)
                                  }
                                />
                              );
                          }
                        }}
                      />
                    </div>
                    {field.isTwo && <div />}
                  </React.Fragment>
                ))}
              </div>
            </>
          )}
        </div>
      </Container>
      <div className='w-[95%] mx-auto py-3 flex justify-end'>
        <Button
          className='sm:w-44 md:w-32'
          onClick={handleSubmit(onSubmit)}
          disabled={postPersonalInfoMutation.status === 'pending'}
          loading={postPersonalInfoMutation.status === 'pending'}
        >
          {isEdit ? staticText.buttons.update : staticText.buttons.next}
        </Button>
      </div>
    </>
  );
};

export default PersonalInfo;
