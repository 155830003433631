import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { IUser } from '@/@types/auth';
import { ISpecialSkills } from '@/@types/profile';
import Container from '@/components/Container';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { INPUT_ERRORS } from '@/constants';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useFormContext } from '@/provider/FormProvider/index';
import { specialSkills } from '@/services/profile';

const SpecialSkills = () => {
  const { profile: staticText, common } = strings;
  const [isEdit, setIsEdit] = useState(false);
  const { user, setUser } = useAuth();
  const { stepFive, handleNext, handleBack, handleSkip, setData } =
    useFormContext();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<ISpecialSkills>({
    mode: 'all',
    defaultValues: user?.skills
      ? { specialSkills: user?.skills }
      : (stepFive ?? { specialSkills: '' }),
  });

  const specialSkillsMutation = useGenericMutation<
    ISpecialSkills,
    IUser | boolean
  >(specialSkills, {
    onSuccess: (result) => {
      if (typeof result !== 'boolean') {
        setData({
          step: 5,
          data: { specialSkills: result?.skills || '' },
        });
        setUser({
          ...user,
          skills: result?.skills || '',
        } as IUser);
        handleNext();
      }
    },
  });

  const onSubmit: SubmitHandler<ISpecialSkills> = (data) => {
    if (isDirty) {
      specialSkillsMutation.mutate(data);
    } else {
      handleNext();
      setData({ step: 5, data });
    }
  };
  return (
    <>
      <Container className='rounded-t-none border-t-0 p-4 w-[95%] mx-auto'>
        <div className='mt-10 md:mt-20'>
          <div className='flex justify-between'>
            <Typography variant='heading' className='md:text-2xl'>
              {staticText.specialSkills}
            </Typography>
            {user?.skills ? (
              <Button
                variant='link'
                className={cn('w-auto h-auto p-1 text-base', {
                  'text-tomatoRed': isEdit,
                })}
                onClick={() => setIsEdit(!isEdit)}
              >
                {isEdit ? strings.common.cancel : strings.common.edit}
              </Button>
            ) : null}
          </div>
          <div className='my-8'>
            <Typography className='flex capitalize font-bold md:text-base mb-3'>
              {staticText.skillsAndTalents}
            </Typography>
            <Controller
              control={control}
              rules={{ required: INPUT_ERRORS.SKILLS.required }}
              name='specialSkills'
              render={({ field: { onChange, value } }) => (
                <Textarea
                  rows={4}
                  value={value as string}
                  onChange={onChange}
                  name='specialSkills'
                  errors={errors}
                  placeholder={common.typeHere}
                  disabled={!!user?.skills && !isEdit}
                  className='border-greyWhite rounded-xl disabled:bg-greyWhite resize-none w-full placeholder:text-quickSilver placeholder:text-sm text-sm md:text-sm font-medium min-h-10 text-primaryBlack max-h-40 mt-1 p-2'
                />
              )}
            />
          </div>
        </div>
      </Container>
      <div className='w-[95%] mx-auto py-3 flex justify-between'>
        <div onClick={() => handleSkip(5)}>
          <Typography className='text-primary underline font-semibold cursor-pointer'>
            {staticText.buttons.skip}
          </Typography>
        </div>
        <div className='flex gap-3'>
          <Button
            variant='outline'
            className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
            onClick={handleBack}
            disabled={specialSkillsMutation.status === 'pending'}
          >
            {staticText.buttons.back}
          </Button>
          <Button
            className='sm:w-44 md:w-32'
            onClick={handleSubmit(onSubmit)}
            disabled={specialSkillsMutation.status === 'pending'}
            loading={specialSkillsMutation.status === 'pending'}
          >
            {isEdit ? staticText.buttons.update : staticText.buttons.next}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SpecialSkills;
