import React, { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import BannerAlert from '@/components/ui/BannerAlert';
import { DASHBOARD_TABS } from '@/constants/dashboard';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { hasAnyValue } from '@/utils/common';

import { ROUTES } from '.';

interface ProtectedRouteProps {
  element: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { token, isCandidate, user } = useAuth();
  const location = useLocation();
  const redirectPath = location.pathname;
  const isSmallScreen = window.innerWidth < 768;

  const filterePersonalInfo = useMemo(() => {
    return Object.fromEntries(
      Object.entries(user?.personalInfo || {}).filter(([key]) => {
        return key !== 'avatar';
      }),
    );
  }, [user?.personalInfo]);

  const isNotificationScreen =
    isSmallScreen && location.pathname.includes(ROUTES.NOTIFICATIONS);

  if (!token) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: redirectPath }} />;
  }

  if (token && isCandidate) {
    const redirectTo =
      location.state?.from && location.state.from !== ROUTES.LOGIN
        ? location.state.from
        : redirectPath || ROUTES.DASHBOARD;
    if (redirectTo !== redirectPath) {
      return <Navigate to={redirectTo} />;
    }
  }

  const isDashboardTabActive = Object.values(DASHBOARD_TABS).some(
    (tab) => location.pathname === `/dashboard/${tab}`,
  );

  return (
    <div>
      {isDashboardTabActive &&
      (!hasAnyValue(filterePersonalInfo) ||
        !hasAnyValue(user?.academicHistory)) ? (
        <div className='px-5 md:px-6 pt-2'>
          <BannerAlert text={strings.common.bannerText} alertType='info' />
        </div>
      ) : null}
      <div
        className={cn('p-5 md:p-6 flex-1 overflow-auto', {
          'p-0': isNotificationScreen,
        })}
      >
        {element}
      </div>
    </div>
  );
};

export default ProtectedRoute;
