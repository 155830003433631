import * as React from 'react';
const ChatFillIcon = () => (
  <svg
    width='19'
    height='19'
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.5609 8.45943V11.8539C14.5609 12.0745 14.5525 12.2867 14.527 12.4904C14.3318 14.7816 12.9825 15.9188 10.4961 15.9188H10.1566C9.94446 15.9188 9.74078 16.0206 9.61348 16.1903L8.59515 17.5481C8.14539 18.1507 7.41556 18.1507 6.96579 17.5481L5.94744 16.1903C5.83712 16.0461 5.59103 15.9188 5.40434 15.9188H5.06489C2.3578 15.9188 1 15.2484 1 11.8539V8.45943C1 5.97297 2.14564 4.62367 4.42843 4.42848C4.6321 4.40302 4.84425 4.39453 5.06489 4.39453H10.4961C13.2031 4.39453 14.5609 5.75233 14.5609 8.45943Z'
      fill='#2B8ABC'
      stroke='#2B8ABC'
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.9567 5.0649V8.45937C17.9567 10.9543 16.811 12.2951 14.5283 12.4903C14.5537 12.2866 14.5622 12.0745 14.5622 11.8539V8.45937C14.5622 5.75228 13.2044 4.39448 10.4973 4.39448H5.06615C4.84551 4.39448 4.63336 4.40297 4.42969 4.42843C4.62487 2.14564 5.97418 1 8.46063 1H13.8918C16.5989 1 17.9567 2.3578 17.9567 5.0649Z'
      stroke='#2B8ABC'
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.7564 10.5474H10.7641'
      stroke='white'
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.78377 10.5474H7.79141'
      stroke='white'
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.81502 10.5474H4.82266'
      stroke='white'
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default ChatFillIcon;
