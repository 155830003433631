import React, { ReactNode } from 'react';

import { CONFIRMATION_MODAL } from '@/constants';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';

import Modal, { ModalProps } from './Modal';
import { Typography } from './Typography';

interface IConfirmationModalProps extends ModalProps {
  icon?: ReactNode;
  textDesc?: string;
  containerClassName?: string;
  isBackoutModal?: boolean;
}

const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  textDesc,
  icon,
  containerClassName,
  isBackoutModal = false,
  ...props
}) => {
  const { primaryBtnLabel } = props;

  return (
    <Modal {...props}>
      <div
        className={cn(
          'flex mt-[-20px] flex-col items-center justify-center',
          containerClassName,
        )}
      >
        <div
          className={cn(
            'size-12 rounded-full bg-bluishLilac flex items-center justify-center',
            {
              'bg-mercury':
                primaryBtnLabel === CONFIRMATION_MODAL.DELETE ||
                primaryBtnLabel === CONFIRMATION_MODAL.REJECT ||
                isBackoutModal,
            },
          )}
        >
          {icon}
        </div>
        <Typography variant='subheading' className='text-lg md:text-xl py-2'>
          {strings.common.confirmation}
        </Typography>
        <Typography className='text-center'>{textDesc}</Typography>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
