import React, { useState } from 'react';
import {
  Cell,
  Label,
  Pie,
  PieChart,
  PieLabelRenderProps,
  ResponsiveContainer,
} from 'recharts';

import { DONUT_COLORS } from '@/constants';

import { Skeleton } from './ui/skeleton';
import { Typography } from './Typography';

interface CustomPieChartProps {
  data: { name: string; value: number; percentage: string }[];
  innerRadius: number;
  outerRadius: number;
  isHalf?: boolean;
  startAngle?: number;
  endAngle?: number;
  height: number;
  width: number;
  isLoading?: boolean;
}

const CustomPieChart: React.FC<CustomPieChartProps> = ({
  data,
  innerRadius,
  outerRadius,
  isHalf = false,
  startAngle = 40,
  endAngle = 440,
  height,
  width,
  isLoading = true,
}) => {
  const hasData = data.reduce(
    (sum, item) => sum + parseFloat(item.percentage),
    0,
  );
  const [activeIndex, setActiveIndex] = useState(-1);
  const RADIAN = Math.PI / 180;

  const RenderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    percent,
    index,
  }: PieLabelRenderProps) => {
    if (index !== activeIndex) return null;

    const radius = isHalf ? 148 : 128;

    const x = Number(cx) + radius * Math.cos(-Number(midAngle) * RADIAN);
    const y = Number(cy) + radius * Math.sin(-Number(midAngle) * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill='#1B1A1B'
        textAnchor='middle'
        dominantBaseline='middle'
      >
        {`${((percent || 0) * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CreateLinearGradients = (colors: string[]) => {
    return colors.map((color, index) => (
      <linearGradient
        key={`gradient${index}`}
        id={`gradient${index}`}
        x1='0%'
        y1='0%'
        x2='100%'
        y2='100%'
      >
        <stop
          offset='0%'
          style={{
            stopColor: `${color}95`,
            stopOpacity: 1,
          }}
        />
        <stop offset='100%' style={{ stopColor: color, stopOpacity: 1 }} />
      </linearGradient>
    ));
  };

  const LoadingSkeleton = ({ noPulsing }: { noPulsing?: boolean }) => {
    return isHalf ? (
      <div className='h-[150px] overflow-hidden my-5'>
        <Skeleton
          noPulsing={noPulsing}
          className='size-[280px] rounded-full flex items-center justify-center my-5'
        >
          <div className='size-32 bg-white rounded-full' />
        </Skeleton>
      </div>
    ) : (
      <Skeleton
        noPulsing={noPulsing}
        className='size-[205px] rounded-full flex items-center justify-center my-5'
      >
        <div className='size-28 bg-white rounded-full' />
      </Skeleton>
    );
  };

  if (isLoading) return <LoadingSkeleton />;
  return (
    <>
      <ResponsiveContainer height={height} width={width}>
        {!hasData ? (
          <LoadingSkeleton noPulsing />
        ) : (
          <PieChart>
            <defs>{CreateLinearGradients(DONUT_COLORS)}</defs>
            <Pie
              data={data}
              dataKey='value'
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              isAnimationActive={false}
              startAngle={isHalf ? 180 : startAngle}
              endAngle={isHalf ? 0 : endAngle}
              paddingAngle={3}
              cornerRadius={6}
              className='focus:outline-none'
              label={RenderCustomizedLabel}
              labelLine={false}
              activeIndex={activeIndex}
              cy={isHalf ? '90%' : '50%'}
            >
              {data.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={`url(#gradient${index})`}
                  onMouseOver={() => setActiveIndex(index)}
                  onMouseOut={() => setActiveIndex(-1)}
                />
              ))}
              <Label
                value='100%'
                position='center'
                className='text-[25px] font-extrabold text-primaryBlack'
              />
            </Pie>
          </PieChart>
        )}
      </ResponsiveContainer>

      <div className='flex gap-2 md:gap-6 mt-4'>
        {data.map(({ name }, index) => (
          <div
            key={`donut-chart-${index}`}
            className='flex items-center gap-1 md:gap-2'
          >
            <div
              className='size-3 rounded-full'
              style={{
                background: `linear-gradient(135deg, ${DONUT_COLORS[index]}95 0%, ${DONUT_COLORS[index]} 100%)`,
              }}
            />
            <Typography className='font-bold md:text-sm'>{name}</Typography>
          </div>
        ))}
      </div>
    </>
  );
};

export default CustomPieChart;
