import React from 'react';
import { FaRegSquareCheck } from 'react-icons/fa6';
import { LuUpload } from 'react-icons/lu';

import Container from '@/components/Container';
import { Typography } from '@/components/Typography';
import { cn } from '@/lib/utils';

interface Props {
  name: string;
  url?: string;
  className?: string;
  onUploadIconClick: (e: { preventDefault: () => void }) => void;
}

const DownloadableFileRow: React.FC<Props> = ({
  name,
  url,
  className,
  onUploadIconClick,
}) => {
  const isUploaded = Boolean(url);

  const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) =>
    isUploaded ? (
      <a
        href={url}
        download
        target='_blank'
        className='h-full'
        rel='noreferrer'
      >
        {children}
      </a>
    ) : (
      <div className='h-full'>{children}</div>
    );

  return (
    <Wrapper>
      <Container
        height='h-10'
        className={cn(
          'bg-simplyViolet flex justify-between items-center overflow-hidden md:px-2 gap-1',
          className,
        )}
      >
        <Typography className='font-semibold capitalize truncate md:text-sm'>
          {name}
        </Typography>
        <div className='flex gap-3 items-center'>
          <LuUpload
            className='text-lg cursor-pointer'
            onClick={onUploadIconClick}
          />
          {isUploaded && (
            <div className='min-w-5'>
              <FaRegSquareCheck className='text-lg text-fernGreen' />
            </div>
          )}
        </div>
      </Container>
    </Wrapper>
  );
};

export default DownloadableFileRow;
