import { cn } from 'src/lib/utils';

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  noPulsing?: boolean;
}

function Skeleton({ noPulsing = true, className, ...props }: SkeletonProps) {
  return (
    <div
      className={cn(
        'rounded-md bg-greyWhite dark:bg-slate-800',
        { 'animate-pulse': !noPulsing },
        className,
      )}
      {...props}
    />
  );
}

export { Skeleton };
