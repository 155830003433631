/* eslint-disable max-lines */
import React, { useCallback, useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import toast, { Toaster } from 'react-hot-toast';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { AxiosError } from 'axios';

import { IGoogleVerifyResponse } from '@/@types/auth';
import { IMessageNotification } from '@/@types/chat';
import Header from '@/components/Header';
import Sidebar from '@/components/Sidebar';
import {
  initializeSocket,
  onConnect,
  onDisconnect,
  socket,
} from '@/config/socketConfig';
import { CHAT_SOCKET_EVENTS } from '@/constants/chat';
import { NOTIFICATIONS_SOCKET_EVENTS } from '@/constants/notifications';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { AuthProvider, useAuth } from '@/provider/AuthProvider';
import { ChatProvider, useChatContext } from '@/provider/ChatProvider';
import { CommonDataProvider } from '@/provider/CommonDataProvider';
import { DrawerProvider, useDrawerContext } from '@/provider/DrawerProvider';
import { FormProvider } from '@/provider/FormProvider/index';
import { PUBLIC_ROUTES, ROUTES } from '@/routes';
import { googleVerifySignup } from '@/services/auth';
import { getChatList } from '@/services/chat';
import { getUnreadNotificationsCount } from '@/services/notifications';
import { messageNotification } from '@/utils/chat';
import { setTokenToLS, setUserDetailToLS } from '@/utils/localstorage';
import { setTokenToSS } from '@/utils/sessionstorage';
import { useQueryClient } from '@tanstack/react-query';

import ProtectedRoute from '../../routes/ProtectedRoute';
import PublicRoute from '../../routes/PublicRoute';
import ForgotPassword from '../Auth/ForgotPassword';
import SetNewPassword from '../Auth/SetNewPassword.tsx';
import SignIn from '../Auth/SignIn';
import SignUp from '../Auth/SignUp';
import Chat from '../Chat';
import Dashboard from '../Dashboard';
import JobDetails from '../JobDetails';
import MyDocument from '../MyDocuments';
import Notifications from '../Notifications';
import Profile from '../Profile';

import 'src/assets/styles/App.css';

const App = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const { setUser, setToken, token, user } = useAuth();
  const { setChatList, allChats, chatList } = useChatContext();
  const { drawerExpand, isSmallScreen, setUnreadNotificationsCount } =
    useDrawerContext();

  const verifyGoogle = useGenericMutation<
    null,
    IGoogleVerifyResponse | boolean
  >(googleVerifySignup, {
    onError: (error) => {
      setTokenToSS('');
      navigate(ROUTES.LOGIN);
      toast.error((error as AxiosError).message);
    },
    onSuccess: (response) => {
      if (typeof response === 'object') {
        setUser(response?.user);
        setToken(response?.accessToken);
        toast.success(strings.signIn.success);
        setUserDetailToLS(response?.user);
        setTokenToLS(response?.accessToken);
        navigate(ROUTES.DASHBOARD);
      }
    },
  });

  const hideLayout = Object.values(PUBLIC_ROUTES).includes(pathname) || !token;

  const { data: notificationCount } = useGenericQuery(
    ['getUnreadNotificationsCount'],
    getUnreadNotificationsCount,
    { enabled: !!token },
  );

  const { data } = useGenericQuery(['chatList'], () => getChatList(true), {
    enabled: !!token,
  });

  const incrementUnreadNotificationCount = useCallback(() => {
    setUnreadNotificationsCount((prev) => prev + 1);
  }, []);

  const handleMessageNotification = useCallback(
    (messageAlert: IMessageNotification) => {
      messageNotification(
        messageAlert,
        allChats,
        chatList,
        setChatList,
        queryClient,
      );
    },
    [allChats, chatList, setChatList, queryClient],
  );

  useEffect(() => {
    if (token && user?._id && !socket?.connected) {
      initializeSocket(user?._id);
    }
  }, [token, user?._id]);

  useEffect(() => {
    if (typeof notificationCount === 'object')
      setUnreadNotificationsCount(notificationCount.count);
  }, [notificationCount]);

  useEffect(() => {
    if (token && user?._id) {
      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on(
        CHAT_SOCKET_EVENTS.MESSAGE_NOTIFICATION,
        handleMessageNotification,
      );
      socket.on(
        NOTIFICATIONS_SOCKET_EVENTS.NEW_NOTIFICATION,
        incrementUnreadNotificationCount,
      );

      return () => {
        socket.off('connect', onConnect);
        socket.off(CHAT_SOCKET_EVENTS.MESSAGE_NOTIFICATION);
        socket.off(NOTIFICATIONS_SOCKET_EVENTS.NEW_NOTIFICATION);
      };
    }
    return () => {};
  }, [
    token,
    handleMessageNotification,
    user?._id,
    socket,
    incrementUnreadNotificationCount,
  ]);

  useEffect(() => {
    if (typeof data === 'object') setChatList(data);
  }, [data]);

  const params = new URLSearchParams(window.location?.search);
  const accessToken = params.get('accessToken');

  useEffect(() => {
    if (accessToken) {
      setTokenToSS(accessToken);
      verifyGoogle.mutate(null);
    }
  }, []);

  const handleRouteNotFound = () => {
    let redirectTo;

    if (token) {
      redirectTo = ROUTES.DASHBOARD;
    } else {
      redirectTo = ROUTES.LOGIN;
    }

    return <Navigate to={redirectTo} />;
  };

  return (
    <div className='flex h-screen'>
      {isSmallScreen && !hideLayout ? (
        <div className={cn({ hidden: !drawerExpand })}>
          <Sidebar />
        </div>
      ) : null}
      <div className='w-full bg-simplyViolet overflow-scroll flex flex-col'>
        <Toaster />
        {hideLayout ? null : <Header />}
        {accessToken ? null : (
          <Routes>
            <Route
              path={ROUTES.DASHBOARD}
              element={<ProtectedRoute element={<Dashboard />} />}
            />
            <Route
              path={ROUTES.PROFILE}
              element={<ProtectedRoute element={<Profile />} />}
            />
            <Route
              path={ROUTES.MY_DOCUMENTS}
              element={<ProtectedRoute element={<MyDocument />} />}
            />
            <Route
              path={ROUTES.SIGNUP}
              element={<PublicRoute element={<SignUp />} />}
            />
            <Route
              path={ROUTES.VERIFY_EMAIL}
              element={<PublicRoute element={<ForgotPassword />} />}
            />
            <Route
              path={ROUTES.LOGIN}
              element={<PublicRoute element={<SignIn />} />}
            />
            <Route
              path={ROUTES.FORGOT_PASSWORD}
              element={<PublicRoute element={<ForgotPassword />} />}
            />
            <Route
              path={ROUTES.SET_NEW_PASSWORD}
              element={<PublicRoute element={<SetNewPassword />} />}
            />
            <Route
              path={ROUTES.CHAT}
              element={<ProtectedRoute element={<Chat />} />}
            />
            <Route
              path={ROUTES.NOTIFICATIONS}
              element={<ProtectedRoute element={<Notifications />} />}
            />
            <Route
              path={ROUTES.JOB_DETAILS}
              element={<ProtectedRoute element={<JobDetails />} />}
            />
            <Route path='*' element={handleRouteNotFound()} />
          </Routes>
        )}
      </div>
    </div>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <CookiesProvider>
        <AuthProvider>
          <DrawerProvider>
            <ChatProvider>
              <FormProvider>
                <CommonDataProvider>
                  <App />
                </CommonDataProvider>
              </FormProvider>
            </ChatProvider>
          </DrawerProvider>
        </AuthProvider>
      </CookiesProvider>
    </Router>
  );
};

export default AppWrapper;
