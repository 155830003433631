import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import LogoutIcon from '@/assets/svgs/LogoutIcon';
import { SIDEBAR_OPTIONS } from '@/constants';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useDrawerContext } from '@/provider/DrawerProvider';
import { ROUTES } from '@/routes';
import { clearStorage } from '@/utils/localstorage';

import { Typography } from '../Typography';
import { Button } from '../ui/button';
import { Separator } from '../ui/seperator';

import DrawerHeader from './DrawerHeader';

const Drawer = () => {
  const staticText = strings.sidebarRoutes;
  const { setUser, setToken } = useAuth();
  const {
    drawerExpand,
    isSmallScreen,
    drawerClosing,
    currentTab,
    setCurrentTab,
    setDrawerExpand,
  } = useDrawerContext();

  const navigate = useNavigate();

  const handleLogout = () => {
    clearStorage();
    setUser(null);
    setToken(null);
    navigate(ROUTES.LOGIN);
  };

  const handleOnClick = (name: string) => {
    setCurrentTab(name);
    if (isSmallScreen) setDrawerExpand(false);
  };

  return (
    <div className='relative z-50'>
      <div
        className={cn(
          'md:relative fixed overflow-y-scroll no-scrollbar border-r border-r-greyWhite bg-white transition-all duration-300 ease-in-out w-16',
          {
            'w-full md:w-48': drawerExpand,
            'slide-in-left': isSmallScreen && drawerExpand,
            'slide-out-left': isSmallScreen && drawerClosing,
          },
        )}
      >
        <div className='flex flex-col h-screen mx-auto max-w-screen-xl'>
          <DrawerHeader />
          <Separator />
          <div className='h-full flex flex-col justify-between'>
            <div className='mx-3 mt-3'>
              {SIDEBAR_OPTIONS.map((option) => (
                <Link
                  key={option.label}
                  to={option.location}
                  onClick={() => handleOnClick(option.label)}
                  className={cn(
                    'flex min-w-fit items-center py-2 px-3 rounded-lg my-1 justify-between',
                    {
                      'bg-primary text-white font-bold rounded-xl':
                        currentTab === option.label,
                    },
                  )}
                >
                  <div className='flex items-center gap-3'>
                    <div className='w-5'>
                      {React.cloneElement(option.icon, {
                        color:
                          currentTab === option.label ? 'white' : '#1B1A1B',
                      })}
                    </div>
                    <Typography
                      className={cn('font-semibold capitalize', {
                        'font-extrabold text-white':
                          currentTab === option.label,
                      })}
                    >
                      {option.label}
                    </Typography>
                  </div>
                </Link>
              ))}
            </div>
            <div>
              <Separator />
              <Button
                variant='link'
                onClick={handleLogout}
                className='flex items-center py-2 px-3 justify-between no-underline mx-3 mb-3 mt-1'
              >
                <div className='flex items-center gap-3'>
                  <div className='w-5'>
                    <LogoutIcon />
                  </div>
                  <Typography className='font-semibold capitalize text-tomatoRed'>
                    {staticText.logout}
                  </Typography>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
