import React from 'react';
import { GoLinkExternal } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';

import Container from '@/components/Container';
import ProfileAvatar from '@/components/ProfileAvatar';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/seperator';
import { STEPPER_STEPS } from '@/constants/profile';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { ROUTES } from '@/routes';
import { getProfileCompletePercentage } from '@/services/profile';

import Vector from '../../../assets/images/Vector.png';

import ProgressBar from './ProgressBar';

const ProfileStatus = () => {
  const staticText = strings.profileStatusSection;
  const { user } = useAuth();
  const { lastName, firstName, avatar } = user || {};
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(ROUTES.PROFILE);
  };

  const { data } = useGenericQuery(['complete-profile-percentage'], () =>
    getProfileCompletePercentage(),
  );

  return (
    <Container className='h-fit relative bg-gradient-to-r from-seaBlue to-seaBlueDark md:px-4 md:py-3'>
      <img src={Vector} className='absolute bottom-0' alt='Background' />
      <div className='grid sm:grid-cols-3 gap-2'>
        <div className='flex items-center gap-2'>
          <ProfileAvatar
            name={`${firstName} ${lastName}`}
            src={(avatar as string) || ''}
            className='md:size-16 size-11'
          />
          <div className='flex flex-col'>
            <Typography className='md:text-sm text-white'>
              {staticText.welcome}
            </Typography>
            <Typography className='text-white font-bold' variant='xl'>
              {`${firstName} ${lastName}`}
            </Typography>
          </div>
        </div>
        <div className='col-span-2 sm:flex justify-between'>
          <div>
            <Typography className='text-white md:text-sm'>
              {staticText.message}
            </Typography>
            <ProgressBar progress={(data as number) || 0} />
          </div>
          <div className='absolute sm:static top-4 right-4'>
            <Button
              variant='outline'
              size='icon'
              className='sm:size-7 size-5 p-0 rounded border border-greyWhite m-1'
              onClick={handleNavigate}
            >
              <GoLinkExternal className='size-4 sm:size-5 text-primary' />
            </Button>
          </div>
        </div>
      </div>
      <div className='mt-4 bg-blue h-auto p-2 lg:h-fit lg:py-2 mx-auto flex flex-wrap  rounded-md '>
        {STEPPER_STEPS.map((item, index, arr) => (
          <React.Fragment key={index}>
            <Typography className='h-full mx-1 sm:mx-auto px-1 text-white md:text-sm font-bold'>
              {item}
            </Typography>
            {index < arr.length - 1 && (
              <Separator orientation='vertical' className='h-5' />
            )}
          </React.Fragment>
        ))}
      </div>
    </Container>
  );
};

export default ProfileStatus;
