import {
  IChat,
  IChatResponse,
  IMessageNotification,
  IReceivedMessage,
  ISocketMessage,
  ITicketChat,
} from '@/@types/chat';
import { socket } from '@/config/socketConfig';
import { CHAT_SOCKET_EVENTS } from '@/constants/chat';
import { QueryClient } from '@tanstack/react-query';

const joinedConversations = new Set();

// Join a conversation (room)
export const joinConversation = (conversationId: string) => {
  if (joinedConversations.has(conversationId)) {
    return;
  }
  socket.emit(CHAT_SOCKET_EVENTS.JOIN_CONVERSATION, { conversationId });
};

// Leave a conversation (room)
export const leaveConversation = (conversationId: string) => {
  socket.emit(CHAT_SOCKET_EVENTS.LEAVE_CONVERSATION, { conversationId });
};

// Send a message to a conversation
export const sendMessage = (messageData: ISocketMessage) => {
  if (messageData?.message.trim() || messageData?.docs?.length)
    socket.emit(CHAT_SOCKET_EVENTS.SEND_MESSAGE, messageData);
};

// Listen for incoming messages
export const receiveMessage = (
  callback: (message: IReceivedMessage) => void,
) => {
  socket.on(CHAT_SOCKET_EVENTS.RECIEVE_MESSAGE, (message) => {
    callback(message);
  });
};

export const messageNotification = (
  messageAlert: IMessageNotification,
  allChats: IChat[],
  chatList: IChatResponse | null,
  setChatList: React.Dispatch<React.SetStateAction<IChatResponse | null>>,
  queryClient: QueryClient,
) => {
  if (chatList) {
    const existingChat = allChats.find(
      (chat) => chat.conversationId === messageAlert.conversationId,
    );
    if (existingChat) {
      if (chatList?.others?.length) {
        const chatInOthers = chatList?.others.findIndex(
          (chat) => chat.conversationId === existingChat.conversationId,
        );
        if (chatInOthers >= 0) {
          const othersChat = chatList.others[chatInOthers]!;
          const newChat = {
            ...othersChat,
            unreadCount: (othersChat?.unreadCount || 0) + 1,
          };
          const newOthersList = chatList.others.map((chat) =>
            chat.conversationId === othersChat?.conversationId ? newChat : chat,
          );
          setChatList((prev) => ({
            tickets: prev?.tickets || [],
            others: newOthersList,
          }));
        }
      } else {
        const ticketIndex = chatList?.tickets.findIndex((ticket) =>
          ticket.conversations.some((conversation) => {
            return conversation.conversationId === messageAlert.conversationId;
          }),
        );
        if (ticketIndex >= 0) {
          const conversationIndex = chatList?.tickets[
            ticketIndex
          ]?.conversations.findIndex(
            (conversation) =>
              conversation.conversationId === messageAlert.conversationId,
          );
          if (conversationIndex !== undefined && conversationIndex >= 0) {
            const conversation =
              chatList.tickets[ticketIndex]!.conversations[conversationIndex]!;
            const newChat = {
              ...conversation,
              unreadCount: (conversation?.unreadCount || 0) + 1,
            };

            const newTicketsList: ITicketChat[] = chatList?.tickets.map(
              (ticket) => ({
                ticket: ticket.ticket,
                conversations: ticket.conversations.map((con) =>
                  con.conversationId === messageAlert.conversationId
                    ? newChat
                    : con,
                ),
              }),
            );

            setChatList((prev) => ({
              others: prev?.others || [],
              tickets: newTicketsList,
            }));
          }
        }
      }
    } else {
      // Invalidate queries if the chat does not exist
      queryClient.invalidateQueries({
        queryKey: ['chatList'],
      });
    }
  }
};

export const convertFilesToBuffers = async (files: File[]) => {
  const buffers = await Promise.all(
    files.map(
      (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve({
              buffer: reader.result, // Binary buffer
              originalname: file.name,
              mimetype: file.type,
              size: file.size,
            });
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
        }),
    ),
  );
  return buffers;
};
